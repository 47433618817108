import { useIntl } from "react-intl";

import { Paragraph, Radio } from "@hexa-ui/components";
import { useUnit } from "effector-react";

import AccountVendorsStore from "@/stores/account/AccountVendorsStore";

import * as Styled from "./VendorSelector.styles";

interface VendorSelectorProps {
  selected: string;
  onSelect: (vendorAccountId: string) => void;
}
export default function VendorSelector({
  selected,
  onSelect,
}: Readonly<VendorSelectorProps>) {
  const { formatMessage } = useIntl();
  const { accountVendors } = useUnit(AccountVendorsStore);

  return (
    <Styled.Container>
      <Styled.OptionsHeader>
        <Paragraph weight="medium">
          {formatMessage({ id: "SearchByPoc.Content.SELLER_HEADER" })}
        </Paragraph>
        <Paragraph weight="medium">
          {formatMessage({ id: "SearchByPoc.Content.ACCOUNT_HEADER" })}
        </Paragraph>
      </Styled.OptionsHeader>
      {accountVendors?.map((accountVendor) => (
        <Radio.Root
          key={`${accountVendor.accountId}-${accountVendor.vendorId}`}
          value={selected}
          onValueChange={onSelect}
        >
          <Styled.Option>
            <Radio.Item
              id={accountVendor.vendorId}
              label={accountVendor.vendorName}
              value={accountVendor.vendorId}
            />
            <Styled.OptionAccountName>
              {accountVendor.accountName}
            </Styled.OptionAccountName>
          </Styled.Option>
        </Radio.Root>
      ))}
    </Styled.Container>
  );
}
