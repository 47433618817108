import { tokens } from "@bees-grow-shared/theme";
import { Heading, Paragraph, SkeletonLoader } from "@hexa-ui/components";
import styled from "styled-components";

import { SEARCH_CONTAINER_EXPANDED_WIDTH } from "../Header/components/startCall/components/searchByPoc/SearchByPoc.styles";
import {
  searchByPocContentLoaderResponsiveStyles,
  searchByPocContentResponsiveStyles,
} from "../Header/components/startCall/components/searchByPoc/SearchByPoc.styles";

export const Container = styled.div`
  display: flex;
  justify-content: center;

  ${searchByPocContentResponsiveStyles}
`;

export const Content = styled.div`
  width: 37.875rem;
`;

export const Title = styled(Heading)`
  margin-bottom: 0.5rem;
`;

export const Description = styled(Paragraph)`
  margin-bottom: 1rem;
`;

export const LoaderContainer = styled.div`
  width: fit-content;
  margin: 0 auto;
  width: ${SEARCH_CONTAINER_EXPANDED_WIDTH}rem;

  ${searchByPocContentLoaderResponsiveStyles}
`;

export const EmptyBehaviorDescription = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  width: 11.25rem;
  line-height: 1.25rem;
  text-align: center;
  margin: 0;
`;

export const EmptyBehavior = styled.div`
  width: fit-content;
  margin: 0.5rem auto 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TitleLoader = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-5"]};
  width: 9.5rem;
  margin-bottom: ${tokens.measure["line-height"]["lineheight-3"]};
`;

export const SubTitleLoader = styled(SkeletonLoader)`
  height: 0.875rem;
  width: 22.5rem;
  margin-bottom: ${tokens.measure["line-height"]["lineheight-5"]};
`;

export const LabelLoader = styled(SkeletonLoader)`
  height: 0.875rem;
  width: 5rem;
  margin-bottom: 0.5rem;
`;

export const ValueLoader = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-3"]};
  width: 8.25rem;
  margin-bottom: ${tokens.measure["line-height"]["lineheight-5"]};
`;
