import { useContext } from "react";

import AgentKPIs from "@/components/agentKPIs/AgentKPIs";
import Header from "@/components/Header/Header";
import SearchByPocContent from "@/components/searchByPocContent/SearchByPocContent";
import { ExpandableSearchContext } from "@/contexts/expandableSearch/ExpandableSearchContext";

import { MainContainer } from "./HomePage.styles";

const HomePage = (): JSX.Element => {
  const { hasExpandedSearch } = useContext(ExpandableSearchContext);

  return (
    <MainContainer data-testid="home-main-page">
      <Header />
      <AgentKPIs />
      {!!hasExpandedSearch && <SearchByPocContent />}
    </MainContainer>
  );
};

export default HomePage;
