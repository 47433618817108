import { Button, Heading } from "@hexa-ui/components";
import styled from "styled-components";

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const GoBackButton = styled(Button)`
  margin-right: 0.9375rem;
`;

export const TitleHeading = styled(Heading)`
  margin-bottom: 1.25rem;
`;
