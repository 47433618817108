import { createEffect } from "effector";

import { AgentKpiDomain } from "@/domains/AgentKpiDomain";
import { getAgentKpiUseCase } from "@/useCases/agentKpi/getAgentKpi/GetAgentKpiUseCase";

export const getAgentKpi = createEffect({
  async handler(): Promise<AgentKpiDomain> {
    return await getAgentKpiUseCase();
  },
});
