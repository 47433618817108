import { tokens } from "@bees-grow-shared/theme";
import { Heading, Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const ValueDescription = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
`;

export const Label = styled(Paragraph)`
  color: ${tokens.color.foundation.txt.secondary};
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
`;

export const ColumnDivider = styled.div`
  border-left: thin solid;
  color: ${tokens.color.component.bg.divider.default};
  margin-right: 1rem;
`;

export const SubContainer = styled.div`
  flex-grow: 1;
  display: inline;
`;

export const CompleteField = styled(Heading)`
  color: ${tokens.color.foundation.txt.success};
`;

export const GapField = styled(Heading)`
  color: ${tokens.color.foundation.txt.neutral};
`;
