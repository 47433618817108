import { useContext } from "react";
import { useIntl } from "react-intl";

import { EmptyState } from "@hexa-ui/components";

import { ExpandableSearchContext } from "@/contexts/expandableSearch/ExpandableSearchContext";
import { ErrorBoundaryProps } from "@/types/ErrorBoundary";

import * as Styled from "./AgentKPIs.styles";
import DailyKPI from "./components/dailyKpi/DailyKPI";
import HeaderKPI from "./components/headerKPI/HeaderKPI";
import { MonthlyKPI } from "./components/monthlyKpi/MonthlyKPI";
import { useAgentKPIs } from "./hooks/useAgentKPIs";

export default function AgentKPIs() {
  const { hasAgentKpiError, getKPIs } = useAgentKPIs();
  const { hasExpandedSearch } = useContext(ExpandableSearchContext);

  if (hasAgentKpiError) {
    return <ErrorBoundary faded={hasExpandedSearch} retryAction={getKPIs} />;
  }

  return (
    <Styled.Container
      data-testid={`${AgentKPIs.name}${hasExpandedSearch ? "-faded" : ""}`}
      faded={hasExpandedSearch}
    >
      <HeaderKPI data-testid={`${AgentKPIs.name}-${HeaderKPI.name}`} />
      <Styled.AgentKPIsContainer
        data-testid={`${AgentKPIs.name}-AgentKPIsContainer`}
      >
        <DailyKPI />
        <MonthlyKPI />
      </Styled.AgentKPIsContainer>
    </Styled.Container>
  );
}

function ErrorBoundary({ retryAction, faded }: Readonly<ErrorBoundaryProps>) {
  const { formatMessage } = useIntl();

  const { PageLevel } = EmptyState;

  return (
    <Styled.ErrorBoundaryContainer
      data-testid={`${AgentKPIs.name}-${ErrorBoundary.name}`}
      faded={faded}
    >
      <PageLevel
        title={formatMessage({ id: "AgentKPIs.UnableLoadPage.TITLE" })}
        description={formatMessage({
          id: "AgentKPIs.UnableLoadPage.DESCRIPTION",
        })}
        actions={[
          {
            name: formatMessage({ id: "AgentKPIs.UnableLoadPage.ACTION" }),
            variant: "secondary",
            action: retryAction,
          },
        ]}
        ilustrationName="beesPageCouldntLoad"
        data-testid={`${AgentKPIs.name}-${ErrorBoundary.name}-${PageLevel.name}`}
      />
    </Styled.ErrorBoundaryContainer>
  );
}
