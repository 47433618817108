import { tokens } from "@bees-grow-shared/theme";
import { IconButton, SearchField } from "@hexa-ui/components";
import styled, { css, keyframes } from "styled-components";

import { fadeReverseAnimation } from "@/pages/homePage/HomePage.styles";

const SEARCH_SHRUNK_WIDTH = 19.4375;
const SEARCH_EXPANDED_WIDTH = 34.375;
const GO_BACK_BUTTON_WIDTH = 2.5;
const GAP_WIDTH = 1;
const DISABLED_OPACITY = 0.32;

export const SEARCH_CONTAINER_EXPANDED_WIDTH =
  GO_BACK_BUTTON_WIDTH + GAP_WIDTH + SEARCH_EXPANDED_WIDTH;

const mediaQuery1280 = (styles: TemplateStringsArray) => css`
  @media (max-width: 1280px) {
    ${styles}
  }
`;

export const openCallResumeButtonResponsiveStyles = mediaQuery1280`
  justify-content: flex-end;
`;

export const headerResponsiveStyles = mediaQuery1280`
  align-items: inherit;
  gap: 1rem;
  flex-direction: column;
`;

export const userInfoSectionResponsiveStyles = mediaQuery1280`
  justify-content: flex-start;
`;

export const searchByPocContentResponsiveStyles = mediaQuery1280`
  margin-top: -3.75rem;
`;

export const searchByPocContentLoaderResponsiveStyles = mediaQuery1280`
  margin-top: -3.75rem;
`;

const moveUp = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-3.75rem);
  }
`;

const moveDown = keyframes`
  from {
    transform: translateY(-3.75rem);
  }
  to {
    transform: translateY(0);
  }
`;

const shrunk = (rightExpanse: number) => css`
  width: ${SEARCH_SHRUNK_WIDTH}rem;
  left: calc(
    100% - ${SEARCH_SHRUNK_WIDTH / 2}rem - ${rightExpanse}px -
      ${tokens.measure.space["spacing-14"]}
  );
`;

const expanded = css`
  width: ${SEARCH_EXPANDED_WIDTH}rem;
  left: calc(
    50% + ${SEARCH_CONTAINER_EXPANDED_WIDTH / 2}rem -
      ${SEARCH_EXPANDED_WIDTH / 2}rem - ${GO_BACK_BUTTON_WIDTH}rem
  );
`;

const shrink = (rightExpanseShrunk) => keyframes`
  from {
    ${expanded}
  }
  to {
    ${shrunk(rightExpanseShrunk)}
  }
`;

const expand = (rightExpanseShrunk) => keyframes`
  from {
    ${shrunk(rightExpanseShrunk)}
  }
  to {
    ${expanded}
  }
`;

const shrinkAnimation = (rightExpanseShrunk: number) => css`
  animation: ${shrink(rightExpanseShrunk)} 800ms ease-out forwards;
`;

const expandAnimation = (rightExpanseShrunk: number) => css`
  animation: ${expand(rightExpanseShrunk)} 800ms ease-out forwards;
`;

const expandShrinkAnimation = (
  expanded: boolean,
  rightExpanseShrunk: number,
) => {
  if (expanded === true)
    return css`
      ${expandAnimation(rightExpanseShrunk)};
    `;
  if (expanded != undefined && expanded === false)
    return css`
      ${shrinkAnimation(rightExpanseShrunk)}
    `;
  return css`
    ${shrunk(rightExpanseShrunk)}
  `;
};

interface ExpandableComponentProps {
  expanded: boolean;
}

interface ExpandableSearchProps extends ExpandableComponentProps {
  disabled: boolean;
}

interface ContainerProps {
  expanded: boolean;
  disabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  position: absolute;
  align-items: center;

  svg {
    opacity: ${(props) => (props.disabled ? DISABLED_OPACITY : 1)};
  }

  @media (max-width: 1280px) {
    ${(props) =>
      props.expanded
        ? css`
            animation: ${moveUp} 0.5s forwards;
          `
        : css`
            animation: ${moveDown} 0.5s forwards;
          `}
  }
`;

export const GoBackButton = styled(IconButton)<ExpandableComponentProps>`
  position: relative;
  left: calc(
    50% - ${SEARCH_CONTAINER_EXPANDED_WIDTH / 2}rem +
      ${GO_BACK_BUTTON_WIDTH / 2}rem
  );
  transform: translate(-50%);
  z-index: 10000;

  ${(props) => fadeReverseAnimation(props.expanded)}
`;

interface SearchProps extends ExpandableComponentProps {
  rightExpanse: number;
}

export const Search = styled.div<SearchProps>`
  position: relative;
  width: ${SEARCH_SHRUNK_WIDTH}rem;
  transform: translate(-50%);

  button {
    background-color: ${tokens.color.component.bg.button.tertiary.pressed};
  }

  ${(props) => expandShrinkAnimation(props.expanded, props.rightExpanse)}
`;

export const Root = styled(SearchField.Root)<ExpandableSearchProps>`
  font-size: ${tokens.measure["font-size"]["fontsize-3"]} !important;
  font-weight: ${tokens.fonts["font-weight"].medium};
  line-height: ${tokens.measure["line-height"]["lineheight-6"]};
  max-height: ${tokens.measure["line-height"]["lineheight-7"]};
  opacity: ${(props) => (props.disabled ? DISABLED_OPACITY : 1)};
`;
