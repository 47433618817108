import { useIntl } from "react-intl";

import { Button, Dialog, Paragraph } from "@hexa-ui/components";

import { useStopAutoDialerModal } from "./hooks/useStopAutoDialerModal";
import * as Styled from "./StopAutoDialerModal.styles";

export default function StopAutoDialerModal() {
  const { formatMessage } = useIntl();
  const { Close, Root } = Dialog;
  const { isModalOpen, handleCloseModal, handleStopCalling } =
    useStopAutoDialerModal();

  return (
    <Root
      open={isModalOpen}
      headerHeight="60px"
      closeButton={true}
      onClose={handleCloseModal}
      onEscapeKeyDown={handleCloseModal}
      onInteractOutside={handleCloseModal}
      onPointerDownOutside={handleCloseModal}
      actions={
        <Styled.ActionsContainer>
          <Close>
            <Styled.GoBackButton
              data-testid="go-back-button"
              size="medium"
              variant="secondary"
              onClick={handleCloseModal}
            >
              {formatMessage({
                id: "StartCall.AutoDialer.StopCalling.Modal.GO_BACK_BUTTON",
              })}
            </Styled.GoBackButton>
          </Close>
          <Button
            data-testid="stop-calling-button"
            onClick={handleStopCalling}
            size="medium"
            variant="primary"
          >
            {formatMessage({
              id: "StartCall.AutoDialer.StopCalling.Modal.STOP_CALLING_BUTTON",
            })}
          </Button>
        </Styled.ActionsContainer>
      }
      title={
        <Styled.TitleHeading data-testid="stop-dialer-modal" size="H3">
          {formatMessage({
            id: "StartCall.AutoDialer.StopCalling.Modal.TITLE",
          })}
        </Styled.TitleHeading>
      }
    >
      <Paragraph>
        {formatMessage({
          id: "StartCall.AutoDialer.StopCalling.Modal.DESCRIPTION",
        })}
      </Paragraph>
    </Root>
  );
}
