import { tokens } from "@bees-grow-shared/theme";
import { Button } from "@hexa-ui/components";
import styled from "styled-components";

export const AutoDialerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AutoDialerButton = styled(Button)<{
  disabled: boolean;
}>`
  > div {
    margin-left: 8px;
    background: ${({ disabled }) =>
      disabled ? `${tokens.color.foundation.bg.disabled} !important` : ""};
  }

  > div > svg {
    width: 16px;
    height: 16px;
  }
`;

export const LoadingButton = styled(Button)`
  width: 11.0625rem;
  grid-template-columns: 1fr 1fr;
  display: grid;
  justify-items: end;

  > div {
    margin-left: 8px;
  }

  > div > svg {
    width: 16px;
    height: 16px;
  }
`;
