import { useIntl } from "react-intl";

import { Heading } from "@hexa-ui/components";
import { useStore } from "effector-react";

import AgentKpiStore from "@/stores/agentKpi/AgentKpiStore";

import { PercentCompleted } from "../genericKPI/components/percentCompleted/PercentCompleted";
import { TimeCompleted } from "../genericKPI/components/timeCompleted/TimeCompleted";
import { GenericKPI } from "../genericKPI/GenericKPI";
import { KPICardSkeleton } from "../kpiCardSkeleton/KPICardSkeleton";
import { ToplineUplift } from "../toplineUplift/ToplineUplift";
import * as Styled from "./DailyKPI.styles";

export default function DailyKPI() {
  const { isLoading, daily } = useStore(AgentKpiStore);
  const { formatMessage } = useIntl();

  if (isLoading) {
    return <Loading />;
  }

  const renderCallListCard = () => (
    <Styled.KpiCard
      elevated={"small"}
      border={"medium"}
      data-testid={`${DailyKPI.name}-${renderCallListCard.name}`}
    >
      <GenericKPI
        title={formatMessage({ id: "AgentKPIs.DailyKPIs.CallList.TITLE" })}
        description={formatMessage({
          id: "AgentKPIs.DailyKPIs.CallList.DESCRIPTION",
        })}
        targetValue={daily?.calls?.target}
        targetCaption={formatMessage({
          id: "AgentKPIs.DailyKPIs.CallList.TARGET_TEXT",
        })}
      >
        <PercentCompleted
          achievementCaption={formatMessage({
            id: "AgentKPIs.DailyKPIs.CallList.COMPLETED_TEXT",
          })}
          achievementNumber={daily?.calls?.completed}
          percentNumber={daily?.calls?.percent}
        />
      </GenericKPI>
    </Styled.KpiCard>
  );

  const renderHitRateCard = () => (
    <Styled.KpiCard
      elevated={"small"}
      border={"medium"}
      data-testid={`${DailyKPI.name}-${renderHitRateCard.name}`}
    >
      <GenericKPI
        title={formatMessage({ id: "AgentKPIs.DailyKPIs.HitRate.TITLE" })}
        description={formatMessage({
          id: "AgentKPIs.DailyKPIs.HitRate.DESCRIPTION",
        })}
        targetValue={daily?.hr?.target}
        targetCaption={formatMessage({
          id: "AgentKPIs.DailyKPIs.HitRate.TARGET_TEXT",
        })}
      >
        <PercentCompleted
          achievementCaption={formatMessage({
            id: "AgentKPIs.DailyKPIs.HitRate.COMPLETED_TEXT",
          })}
          achievementNumber={daily?.hr?.completed}
          percentNumber={daily?.hr?.percent}
        />
      </GenericKPI>
    </Styled.KpiCard>
  );

  const renderUpliftCard = () => (
    <Styled.KpiCard
      elevated={"small"}
      border={"medium"}
      data-testid={`${DailyKPI.name}-${renderUpliftCard.name}`}
    >
      <GenericKPI
        title={formatMessage({ id: "AgentKPIs.DailyKPIs.ToplineUplift.TITLE" })}
        description={formatMessage({
          id: "AgentKPIs.DailyKPIs.ToplineUplift.DESCRIPTION",
        })}
        showTarget={false}
      >
        <ToplineUplift />
      </GenericKPI>
    </Styled.KpiCard>
  );

  const renderAverageHandleTimeCard = () => (
    <Styled.KpiCard
      elevated={"small"}
      border={"medium"}
      data-testid={`${DailyKPI.name}-${renderAverageHandleTimeCard.name}`}
    >
      <GenericKPI
        title={formatMessage({
          id: "AgentKPIs.DailyKPIs.AverageHandleTime.TITLE",
        })}
        description={formatMessage({
          id: "AgentKPIs.DailyKPIs.AverageHandleTime.DESCRIPTION",
        })}
        targetValue={daily?.aht?.target}
        targetCaption={formatMessage({
          id: "AgentKPIs.DailyKPIs.AverageHandleTime.TARGET_TEXT",
        })}
      >
        <TimeCompleted
          achievementCaption={formatMessage({
            id: "AgentKPIs.DailyKPIs.AverageHandleTime.COMPLETED_TEXT",
          })}
          achievementValue={daily?.aht?.completed}
        />
      </GenericKPI>
    </Styled.KpiCard>
  );

  return (
    <Styled.Container data-testid={`${DailyKPI.name}`}>
      <Styled.Header>
        <Heading size="H4" data-testid={`${DailyKPI.name}-${Heading.name}`}>
          {formatMessage({ id: "AgentKPIs.DailyKPIs.TITLE" })}
        </Heading>
      </Styled.Header>
      <Styled.Cards data-testid={`${DailyKPI.name}-Cards`}>
        {daily?.calls && renderCallListCard()}
        {daily?.hr && renderHitRateCard()}
        {daily?.uplift && renderUpliftCard()}
        {daily?.aht && renderAverageHandleTimeCard()}
      </Styled.Cards>
    </Styled.Container>
  );
}

function Loading() {
  return (
    <Styled.Container data-testid={`${DailyKPI.name}-${Loading.name}`}>
      <Styled.Loader />
      <Styled.Cards
        data-testid={`${DailyKPI.name}-${Loading.name}-${Styled.Cards.name}`}
      >
        {Array.from({ length: 4 }).map((_, index) => (
          <KPICardSkeleton key={`${KPICardSkeleton.name}-${index}`} />
        ))}
      </Styled.Cards>
    </Styled.Container>
  );
}
