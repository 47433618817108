import React from "react";

const RotateIcon: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="black"
        d="M13.454 3.045c.28 0 .51.21.542.482l.004.064v3.273c0 .28-.21.51-.482.542l-.064.004h-3.273a.546.546 0 01-.063-1.088l.063-.003h1.899L10.543 4.87A4.364 4.364 0 1011.57 9.41a.546.546 0 011.029.363 5.455 5.455 0 11-1.415-5.799l.118.114 1.606 1.513v-2.01c0-.28.21-.51.482-.542l.063-.004z"
      ></path>
    </svg>
  );
};

export default RotateIcon;
