import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useToast } from "@bees-grow-shared/business-components";
import {
  agentCallSharedService,
  PocCallOrigin,
  useFeatureToggleEnabledByOrgId,
  useSharedUnit,
} from "@bees-grow-shared/services";

import { ACCOUNT_PATH } from "@/config/constants";
import { GROW_PORTAL_CTI } from "@/config/featureToggles";
import { GetNextPocUseCase } from "@/useCases/agentCall/getNextPoc/GetNextPocUseCase";

interface UseCallManuallyProps {
  handleOnClick: () => void;
  isCallManuallyLoading: boolean;
  isCallManuallyDisabled: boolean;
}

export function useCallManually(): UseCallManuallyProps {
  const agentCallService = agentCallSharedService();

  const {
    startCall: {
      isLoading,
      error: isCallManuallyError,
      errorMessage: errorMessageCallManually,
    },
    ctiEnabled,
    pocCallOrigin,
  } = useSharedUnit(agentCallService);

  const { isEnabled: isCTIEnabled } =
    useFeatureToggleEnabledByOrgId(GROW_PORTAL_CTI);

  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const toast = useToast();

  const missionPriorityErrorMessage = formatMessage({
    id: "StartCall.GetNextPoc.CustomErrors.GROUP_WITHOUT_MISSION_PRIORITY",
  });

  const handleOnClick = useCallback(() => {
    const getNextPoc = GetNextPocUseCase({ ctiEnabled: isCTIEnabled }).then(
      (response) => {
        navigate(ACCOUNT_PATH);
        return response;
      },
    );

    agentCallService.setCallManually(getNextPoc);
  }, [agentCallService, isCTIEnabled, navigate]);

  const handleToastType = useCallback(() => {
    if (errorMessageCallManually === missionPriorityErrorMessage) {
      return "warning";
    }
    return "error";
  }, [errorMessageCallManually, missionPriorityErrorMessage]);

  const isCallManually = useMemo(
    () => !ctiEnabled && pocCallOrigin === PocCallOrigin.GET_NEXT_CLIENT,
    [ctiEnabled, pocCallOrigin],
  );

  const isCallManuallyLoading = useMemo(
    () => isLoading && isCallManually,
    [isLoading, isCallManually],
  );

  const isCallManuallyDisabled = useMemo(
    () => isLoading && !isCallManually,
    [isLoading, isCallManually],
  );

  useEffect(() => {
    if (isCallManuallyError && isCallManually) {
      toast({
        showCloseButton: true,
        type: handleToastType(),
        message: errorMessageCallManually,
      });
    }
  }, [
    errorMessageCallManually,
    handleToastType,
    isCallManually,
    isCallManuallyError,
    toast,
  ]);

  return {
    handleOnClick,
    isCallManuallyLoading,
    isCallManuallyDisabled,
  };
}
