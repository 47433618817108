import { axiosInstance } from "@bees-grow-shared/services";

import { GROW_AGENT_CALL_SERVICE_ENDPOINTS } from "../endpoints";
import { StartCallResponse } from "../model/StartCallResponse";
import {
  CreateOpenCallRequestBody,
  CreateOpenCallRequestHeaders,
} from "./model/CreateOpenCallRequest";

interface CreateOpenCallProps {
  body: CreateOpenCallRequestBody;
  headers: CreateOpenCallRequestHeaders;
}

export async function createOpenCall({
  body,
  headers,
}: CreateOpenCallProps): Promise<StartCallResponse> {
  const { data } = await axiosInstance.post<StartCallResponse>(
    GROW_AGENT_CALL_SERVICE_ENDPOINTS.OPEN_CALL(),
    body,
    { headers: { ...headers } },
  );

  return data;
}
