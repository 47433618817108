import { axiosInstance } from "@bees-grow-shared/services";

import { GROW_ACCOUNT_SEARCH_SERVICE_ENDPOINTS } from "../endpoints";
import { AccountResponse } from "./model/AccountResponse";
import {
  GetAccountsHeaderParamsRequest,
  GetAccountsQueryParamsRequest,
} from "./model/GetAccountsRequest";

interface GetAccountsProps {
  query: GetAccountsQueryParamsRequest;
  headers: GetAccountsHeaderParamsRequest;
}

export async function getAccounts({
  query,
  headers,
}: GetAccountsProps): Promise<AccountResponse[]> {
  const { accountId } = query;

  const { data } = await axiosInstance.get<AccountResponse[]>(
    GROW_ACCOUNT_SEARCH_SERVICE_ENDPOINTS.GET_ACCOUNTS(accountId),
    { headers: { ...headers } },
  );

  return data;
}
