import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useToast } from "@bees-grow-shared/business-components";
import {
  agentCallSharedService,
  PocCallOrigin,
  userConfigSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { useStore } from "effector-react";

import { ExpandableSearchContext } from "@/contexts/expandableSearch/ExpandableSearchContext";
import {
  getAccounts,
  resetAccounts,
} from "@/stores/account/AccountVendorsEvents";
import AccountVendorsStore from "@/stores/account/AccountVendorsStore";

interface UseSearchByPocProps {
  accountId: string;
  expanded: boolean;
  isSearchByPocDisabled: boolean;
  handleSearchClick: () => void;
  handleSearchSubmit: () => void;
  handleSearchChange: (e: React.FormEvent<HTMLInputElement>) => void;
  handleSearchClear: () => void;
  handleSearchReset: () => void;
}

export function useSearchByPoc(): UseSearchByPocProps {
  const agentCallService = agentCallSharedService();
  const [accountId, setAccountId] = useState<string>("");
  const { error, errorMessage } = useStore(AccountVendorsStore);
  const { hasExpandedSearch, expandSearch, shrinkSearch } = useContext(
    ExpandableSearchContext,
  );
  const { groupId } = userConfigSharedService().getStoreValue();

  const {
    startCall: { isLoading },
    pocCallOrigin,
  } = useSharedUnit(agentCallService);

  const toast = useToast();

  const isSearchByPoc = useMemo(
    () => pocCallOrigin === PocCallOrigin.SEARCH_BY_POC,
    [pocCallOrigin],
  );

  const isSearchByPocDisabled = useMemo(
    () => isLoading && !isSearchByPoc,
    [isLoading, isSearchByPoc],
  );

  useEffect(() => {
    if (error) {
      toast({
        showCloseButton: true,
        type: "error",
        message: errorMessage,
      });
    }
  }, [error, errorMessage, toast]);

  const handleSearchClick = useCallback(() => {
    expandSearch();
  }, [expandSearch]);

  const handleSearchChange = useCallback((e) => {
    setAccountId(e.currentTarget.value);
  }, []);

  const handleSearchSubmit = useCallback(() => {
    getAccounts({ accountId, groupId });
  }, [accountId, groupId]);

  const handleSearchClear = useCallback(() => {
    setAccountId("");
  }, [setAccountId]);

  const handleSearchReset = useCallback(() => {
    handleSearchClear();
    resetAccounts();
    shrinkSearch();
  }, [shrinkSearch, handleSearchClear]);

  const expanded = useMemo(() => hasExpandedSearch, [hasExpandedSearch]);

  return {
    accountId,
    expanded,
    isSearchByPocDisabled,
    handleSearchClick,
    handleSearchSubmit,
    handleSearchChange,
    handleSearchClear,
    handleSearchReset,
  };
}
