import * as Styled from "./PercentCompleted.styles";

interface PercentCompletedProps {
  achievementCaption: string;
  achievementNumber: number | string;
  percentNumber: number;
}

export const PercentCompleted = ({
  achievementNumber,
  achievementCaption,
  percentNumber,
}: PercentCompletedProps): JSX.Element => {
  return (
    <Styled.Container data-testid="kpi-card-percent-completed">
      <Styled.Header>{Math.round(percentNumber)}%</Styled.Header>
      <Styled.ColumnDivider />
      <div>
        <Styled.CurrentValue size="H4">
          {String(achievementNumber).padStart(2, "0")}
        </Styled.CurrentValue>
        <Styled.CurrentValueLabel>
          {achievementCaption}
        </Styled.CurrentValueLabel>
      </div>
    </Styled.Container>
  );
};
