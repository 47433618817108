import { useContext } from "react";
import { useIntl } from "react-intl";

import ArrowLeftIcon from "@/assets/searchByPoc/ArrowLeftIcon";
import { ExpandableSearchContext } from "@/contexts/expandableSearch/ExpandableSearchContext";

import { useSearchByPoc } from "./hooks/useSearchByPoc";
import * as Styled from "./SearchByPoc.styles";

export function SearchByPoc() {
  const { formatMessage } = useIntl();
  const {
    accountId,
    expanded,
    isSearchByPocDisabled,
    handleSearchClick,
    handleSearchChange,
    handleSearchSubmit,
    handleSearchClear,
    handleSearchReset,
  } = useSearchByPoc();
  const { startCallButtonsWidth } = useContext(ExpandableSearchContext);

  if (!startCallButtonsWidth) {
    return <></>;
  }

  return (
    <Styled.Container
      data-testid={`${SearchByPoc.name}`}
      expanded={expanded}
      disabled={isSearchByPocDisabled}
    >
      <Styled.GoBackButton
        data-testid={`${SearchByPoc.name}-GoBackButton`}
        expanded={expanded}
        icon={ArrowLeftIcon}
        variant="tertiary"
        onClick={handleSearchReset}
      />
      <Styled.Search
        data-testid={`${SearchByPoc.name}-Search`}
        rightExpanse={startCallButtonsWidth}
        expanded={expanded}
      >
        <Styled.Root
          data-testid={`${SearchByPoc.name}-Search-Root`}
          expanded={expanded}
          placeholder={formatMessage({
            id: "SearchByPoc.SearchField.PLACEHOLDER",
          })}
          size="large"
          value={accountId}
          onClick={handleSearchClick}
          onClear={handleSearchClear}
          onChange={handleSearchChange}
          onSearch={handleSearchSubmit}
          disabled={isSearchByPocDisabled}
        />
      </Styled.Search>
    </Styled.Container>
  );
}
