import {
  AccountVendorDomain,
  AccountVendorsDomain,
} from "@/domains/AccountVendorsDomain";
import { AccountResponse } from "@/services/account/getAccounts/model/AccountResponse";

function getAccountConvert(account: AccountResponse) {
  return {
    accountId: account?.accountId,
    vendorAccountId: account?.vendorAccountId,
    accountName: account?.accountName,
    vendorId: account?.vendorId,
    vendorName: account?.vendorName,
  } as AccountVendorDomain;
}

export function getAccountsConvert(
  accounts: AccountResponse[],
): AccountVendorsDomain {
  if (!Array.isArray(accounts)) {
    return {
      accountVendors: [],
    };
  }

  return {
    accountVendors: accounts.map(getAccountConvert),
  } as AccountVendorsDomain;
}
