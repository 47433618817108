import { Button } from "@hexa-ui/components";
import styled from "styled-components";

import { openCallResumeButtonResponsiveStyles } from "../searchByPoc/SearchByPoc.styles";

export const Container = styled.div`
  display: flex;
  ${openCallResumeButtonResponsiveStyles}
`;

export const ButtonContainer = styled(Button)`
  > div {
    margin-left: 8px;
  }

  > div > svg {
    width: 16px;
    height: 16px;
  }
`;
