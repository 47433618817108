import { AgentCall as AgentCallDomain } from "@bees-grow-shared/services";

import { StartCallResponse } from "@/services/agentCall/model/StartCallResponse";

export function getOpenCallConvert(
  response: StartCallResponse,
): AgentCallDomain {
  return {
    callId: response.callId,
    startCallDate: response.startCallDate,
    orderInfo: response.orderInfo,
    pocCallOrigin: response.pocCallOrigin,
    ctiEnabled: response.ctiEnabled,
  } as AgentCallDomain;
}
