import { useIntl } from "react-intl";

import { Button } from "@hexa-ui/components";

import * as Styled from "./CallManually.styles";
import { useCallManually } from "./hooks/useCallManually";

export default function CallManually() {
  const { formatMessage } = useIntl();
  const { handleOnClick, isCallManuallyLoading, isCallManuallyDisabled } =
    useCallManually();

  const title = formatMessage({ id: "StartCall.GetNextPoc.CALL_MANUALLY" });

  if (isCallManuallyLoading) {
    return <Loading />;
  }

  return (
    <Button
      size="medium"
      variant="secondary"
      data-testid="call-manually-button"
      onClick={handleOnClick}
      disabled={isCallManuallyDisabled}
    >
      {title}
    </Button>
  );
}

function Loading() {
  return (
    <Styled.LoadingButton
      isLoading
      size="medium"
      variant="secondary"
      data-testid="call-manually-loading"
    >
      Loading
    </Styled.LoadingButton>
  );
}
