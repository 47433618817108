import { useCallback, useMemo } from "react";

import {
  agentCallSharedService,
  autoDialerSharedService,
  ctiSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";

interface UseStopAutoDialerModalProps {
  handleCloseModal: () => void;
  handleStopCalling: () => void;
  isModalOpen: boolean;
}

export function useStopAutoDialerModal(): UseStopAutoDialerModalProps {
  const autoDialerService = autoDialerSharedService();
  const agentCallService = agentCallSharedService();
  const ctiService = ctiSharedService();
  const { showStopCallingModal } = useSharedUnit(autoDialerService);

  const handleCloseModal = useCallback(() => {
    autoDialerService.setAutoDialerState({ showStopCallingModal: false });
  }, [autoDialerService]);

  const handleStopCalling = useCallback(() => {
    ctiService.close();
    agentCallService.resetStartCallState();
    autoDialerService.resetAutoDialer();
  }, [agentCallService, autoDialerService, ctiService]);

  const isModalOpen = useMemo(
    () => showStopCallingModal,
    [showStopCallingModal],
  );

  return {
    handleCloseModal,
    handleStopCalling,
    isModalOpen,
  };
}
