import { useCurrencyFormatter } from "@bees-grow-shared/services";
import { useStore } from "effector-react";

import AgentKpiStore from "@/stores/agentKpi/AgentKpiStore";

interface Uplift {
  formattedComplete: string;
  formattedGap: string;
  target: number;
  percent: number;
}
interface UseTopLineUpliftProps {
  daily: Uplift;
  monthly: Uplift;
}

export function useToplineUplift(): UseTopLineUpliftProps {
  const {
    daily: { uplift: dailyUplift },
    monthly: { uplift: monthlyUplift },
  } = useStore(AgentKpiStore);

  const formattedDailyComplete = useCurrencyFormatter(dailyUplift.completed, {
    minimumFractionDigits: 2,
  });

  const formattedDailyGap = useCurrencyFormatter(dailyUplift.gap, {
    minimumFractionDigits: 2,
  });
  const formattedMonthlyComplete = useCurrencyFormatter(
    monthlyUplift.completed,
    {
      minimumFractionDigits: 2,
    },
  );

  const formattedMonthlyGap = useCurrencyFormatter(monthlyUplift.gap, {
    minimumFractionDigits: 2,
  });

  return {
    daily: {
      formattedComplete: formattedDailyComplete,
      formattedGap: formattedDailyGap,
      target: dailyUplift.target,
      percent: dailyUplift.percent,
    },
    monthly: {
      formattedComplete: formattedMonthlyComplete,
      formattedGap: formattedMonthlyGap,
      target: monthlyUplift.target,
      percent: monthlyUplift.percent,
    },
  };
}
