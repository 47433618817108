import { Button } from "@hexa-ui/components";
import styled from "styled-components";

export const GoBackButton = styled(Button)`
  margin-right: 0.9375rem;
`;

export const GoToPocButton = styled(Button)`
  > div {
    margin-left: 8px;
  }

  > div > svg {
    width: 16px;
    height: 16px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;
