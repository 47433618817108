import { Account as AccountDomain } from "@bees-grow-shared/services";

import { intl } from "@/i18n";
import { getAccountsInfo } from "@/services/account/getAccountsInfo/GetAccountsInfoService";

import { getAccountInfoConvert } from "./converters/GetAccountsInfoConverter";

interface GetAccountsInfoUseCaseProps {
  accountId: string;
  vendorId: string;
}

export const getAccountsInfoUseCase = async ({
  accountId,
  vendorId,
}: GetAccountsInfoUseCaseProps): Promise<AccountDomain> => {
  try {
    const response = await getAccountsInfo({
      query: { accountId },
      headers: { vendorId },
    });

    return getAccountInfoConvert(response, vendorId);
  } catch {
    throw new Error(
      intl.formatMessage({
        id: "Errors.GENERIC_ERROR",
      }),
    );
  }
};
