function NoSearchResultsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      fill="none"
      viewBox="0 0 128 128"
    >
      <g opacity="0.3">
        <mask
          id="mask0_6909_12142"
          style={{ maskType: "luminance" }}
          width="57"
          height="57"
          x="5"
          y="20"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M33.806 76.544c-15.368 0-27.826-12.458-27.826-27.826 0-15.367 12.458-27.825 27.826-27.825S61.632 33.35 61.632 48.718c0 15.368-12.458 27.826-27.826 27.826z"
          ></path>
        </mask>
        <g mask="url(#mask0_6909_12142)">
          <path
            fill="url(#paint0_linear_6909_12142)"
            d="M33.806 76.544c-15.368 0-27.826-12.458-27.826-27.826 0-15.367 12.458-27.825 27.826-27.825S61.632 33.35 61.632 48.718c0 15.368-12.458 27.826-27.826 27.826z"
          ></path>
        </g>
      </g>
      <g opacity="0.3">
        <mask
          id="mask1_6909_12142"
          style={{ maskType: "luminance" }}
          width="48"
          height="48"
          x="80"
          y="59"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M104.384 106.944c-13.043 0-23.616-10.573-23.616-23.616s10.573-23.616 23.616-23.616S128 70.285 128 83.328s-10.573 23.616-23.616 23.616z"
          ></path>
        </mask>
        <g mask="url(#mask1_6909_12142)">
          <path
            fill="url(#paint1_linear_6909_12142)"
            d="M104.384 106.944c-13.043 0-23.616-10.573-23.616-23.616s10.573-23.616 23.616-23.616S128 70.285 128 83.328s-10.573 23.616-23.616 23.616z"
          ></path>
        </g>
      </g>
      <mask
        id="mask2_6909_12142"
        style={{ maskType: "luminance" }}
        width="124"
        height="104"
        x="3"
        y="3"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          d="M24.896 65.678c0 22.471 18.218 40.69 40.69 40.69 22.47 0 40.689-18.219 40.689-40.69l20.018.285-8.036-62.407H9.962L3.626 65.97l21.27-.292z"
        ></path>
      </mask>
      <g mask="url(#mask2_6909_12142)">
        <mask
          id="mask3_6909_12142"
          style={{ maskType: "luminance" }}
          width="83"
          height="83"
          x="24"
          y="24"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M65.571 106.332c-22.472 0-40.69-18.217-40.69-40.69 0-22.472 18.218-40.69 40.69-40.69 22.472 0 40.69 18.218 40.69 40.69 0 22.473-18.218 40.69-40.69 40.69z"
          ></path>
        </mask>
        <g mask="url(#mask3_6909_12142)">
          <path
            fill="url(#paint2_linear_6909_12142)"
            d="M65.571 106.332c-22.472 0-40.69-18.217-40.69-40.69 0-22.472 18.218-40.69 40.69-40.69 22.472 0 40.69 18.218 40.69 40.69 0 22.473-18.218 40.69-40.69 40.69z"
          ></path>
        </g>
      </g>
      <mask
        id="mask4_6909_12142"
        style={{ maskType: "luminance" }}
        width="124"
        height="104"
        x="3"
        y="3"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          d="M24.896 65.678c0 22.471 18.218 40.69 40.69 40.69 22.47 0 40.689-18.219 40.689-40.69l20.018.285-8.036-62.407H9.962L3.626 65.97l21.27-.292z"
        ></path>
      </mask>
      <g mask="url(#mask4_6909_12142)">
        <path
          fill="#E5D770"
          d="M42.695 48.242l-10.133-7.744a1.4 1.4 0 00-1.92.206l-9.33 10.916a1.399 1.399 0 00.25 2.047l11.27 8.072 9.863-13.497zM87.069 48.242l10.133-7.744a1.4 1.4 0 011.92.206l9.33 10.916a1.4 1.4 0 01-.249 2.047L96.924 61.74l-9.856-13.497z"
        ></path>
        <path
          fill="#8C7C1B"
          d="M32.925 61.739h64l-9.856-13.497H42.78l-9.856 13.497z"
        ></path>
        <path
          fill="url(#paint3_linear_6909_12142)"
          d="M32.918 61.739h64.007v64.007H32.918V61.739z"
        ></path>
        <path
          fill="#E5D770"
          d="M96.84 61.739H32.881L29.42 73.99c-.54 1.899.917 3.783 2.923 3.783H97.38c2.005 0 3.463-1.877 2.923-3.783L96.839 61.74z"
        ></path>
        <path
          fill="#000"
          d="M32.932 77.767H96.89v3.364H32.932v-3.364z"
          opacity="0.16"
        ></path>
      </g>
      <g opacity="0.5">
        <mask
          id="mask5_6909_12142"
          style={{ maskType: "luminance" }}
          width="15"
          height="15"
          x="101"
          y="35"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M108.75 49.365a7.011 7.011 0 110-14.022 7.011 7.011 0 010 14.022z"
          ></path>
        </mask>
        <g mask="url(#mask5_6909_12142)">
          <path
            fill="url(#paint4_linear_6909_12142)"
            d="M108.75 49.365a7.011 7.011 0 110-14.022 7.011 7.011 0 010 14.022z"
          ></path>
        </g>
      </g>
      <g opacity="0.5">
        <mask
          id="mask6_6909_12142"
          style={{ maskType: "luminance" }}
          width="15"
          height="15"
          x="13"
          y="94"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M20.793 108.601a7.012 7.012 0 110-14.023 7.012 7.012 0 010 14.023z"
          ></path>
        </mask>
        <g mask="url(#mask6_6909_12142)">
          <path
            fill="url(#paint5_linear_6909_12142)"
            d="M20.793 108.601a7.012 7.012 0 110-14.023 7.012 7.012 0 010 14.023z"
          ></path>
        </g>
      </g>
      <path
        fill="#D85951"
        fillRule="evenodd"
        d="M91.776 83.072c0 6.998 5.675 12.672 12.672 12.672 6.997 0 12.672-5.674 12.672-12.672 0-6.997-5.675-12.672-12.672-12.672-6.997 0-12.672 5.675-12.672 12.672z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M98.297 87.709l3.172-3.172-3.172-3.171a2.112 2.112 0 112.987-2.987l3.172 3.171 3.171-3.171a2.112 2.112 0 112.987 2.987l-3.172 3.171 3.172 3.172a2.111 2.111 0 11-2.987 2.986l-3.171-3.171-3.172 3.171a2.112 2.112 0 11-2.987-2.986z"
        clipRule="evenodd"
        opacity="0.16"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M98.297 86.443l3.172-3.172-3.172-3.171a2.112 2.112 0 112.987-2.987l3.172 3.172 3.171-3.172a2.112 2.112 0 012.987 2.987l-3.172 3.171 3.172 3.172a2.111 2.111 0 11-2.987 2.987l-3.171-3.172-3.172 3.172a2.112 2.112 0 11-2.987-2.987z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_6909_12142"
          x1="61.636"
          x2="5.982"
          y1="48.72"
          y2="48.72"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4"></stop>
          <stop offset="1" stopColor="#EEEFF1"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_6909_12142"
          x1="128"
          x2="80.764"
          y1="83.33"
          y2="83.33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4"></stop>
          <stop offset="1" stopColor="#EEEFF1"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_6909_12142"
          x1="106.257"
          x2="24.883"
          y1="65.643"
          y2="65.643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4"></stop>
          <stop offset="1" stopColor="#EEEFF1"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_6909_12142"
          x1="64.926"
          x2="64.926"
          y1="125.741"
          y2="61.736"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8AF32"></stop>
          <stop offset="1" stopColor="#D8BC32"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_6909_12142"
          x1="115.764"
          x2="101.735"
          y1="42.351"
          y2="42.351"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4"></stop>
          <stop offset="1" stopColor="#EEEFF1"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_6909_12142"
          x1="27.81"
          x2="13.78"
          y1="101.589"
          y2="101.589"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4"></stop>
          <stop offset="1" stopColor="#EEEFF1"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default NoSearchResultsIcon;
