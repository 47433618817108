import { tokens } from "@bees-grow-shared/theme";
import { Heading, Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div<{ fit?: boolean }>`
  padding: 1rem;
  height: ${(props) => (props.fit ? "auto" : "204px")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Content = styled.div<{ fit?: boolean }>`
  display: flex;
  justify-content: space-between;
  height: ${(props) => (props.fit ? "auto" : "100px")};
  padding: ${(props) => (props.fit ? "0.5rem" : "0")} 0;
  align-items: center;
`;

export const Target = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const TargetValue = styled(Heading)`
  color: ${tokens.color.foundation.txt.success};
`;

export const InfoDescription = styled(Paragraph)`
  color: ${tokens.color.foundation.txt.secondary};
`;

export const TargetName = styled(Paragraph)`
  color: ${tokens.color.foundation.txt.secondary};
  text-align: right;
`;
