import {
  accountSharedService,
  AgentCall,
  PocCallOrigin,
} from "@bees-grow-shared/services";

import { intl } from "@/i18n";
import { createOpenCall } from "@/services/agentCall/createOpenCall/CreateOpenCallService";
import { getAccountsInfoUseCase } from "@/useCases/account/getAccountsInfo/GetAccountsInfoUseCase";

import { createOpenCallConvert } from "./converters/CreateOpenCallConvert";

interface CreateOpenCallUseCaseProps {
  accountId: string;
  vendorAccountId: string;
  vendorId: string;
  pocCallOrigin: PocCallOrigin;
  ctiEnabled: boolean;
}

export async function createOpenCallUseCase({
  accountId,
  vendorAccountId,
  vendorId,
  pocCallOrigin,
  ctiEnabled,
}: CreateOpenCallUseCaseProps): Promise<AgentCall> {
  const accountService = accountSharedService();

  try {
    const accountInfoResponse = await getAccountsInfoUseCase({
      accountId,
      vendorId,
    });

    const createOpenCallResponse = await createOpenCall({
      body: {
        accountId,
        vendorAccountId,
        pocCallOrigin,
        ctiEnabled,
      },
      headers: {
        vendorId,
      },
    });

    accountService.setAccount(accountInfoResponse);
    return createOpenCallConvert(createOpenCallResponse);
  } catch {
    throw new Error(
      intl.formatMessage({
        id: "Errors.GENERIC_ERROR",
      }),
    );
  }
}
