import { Account as AccountDomain } from "@bees-grow-shared/services";

import { AccountInfoResponse } from "@/services/account/getAccountsInfo/model/AccountInfoResponse";

export function getAccountInfoConvert(
  response: AccountInfoResponse,
  vendorId: string,
): AccountDomain {
  return {
    vendorId,
    accountId: response.accountId,
    vendorAccountId: response.vendorAccountId,
    channel: response.channel,
    channelName: response.channelName,
    customerAccountId: response.customerAccountId,
    formattedAddress: response.formattedAddress,
    liquorLicenseNumber: response.liquorLicenseNumber,
    name: response.name,
    owner: {
      email: response.owner?.email,
      firstName: response.owner?.firstName,
      fullName: response.owner?.fullName,
      lastName: response.owner?.lastName,
      phone: response.owner?.phone,
    },
    potential: response.potential,
    potentialName: response.potentialName,
    representative: {
      email: response.representative?.email,
      name: response.representative?.name,
      phone: response.representative?.phone,
    },
    sales: {
      areaName: response.sales?.areaName,
      areaRepName: response.sales?.areaRepName,
      org: response.sales?.org,
      regionCode: response.sales?.regionCode,
      regionName: response.sales?.regionName,
      subregionCode: response.sales?.subregionCode,
      subregionName: response.sales?.subregionName,
      supervision: response.sales?.supervision,
      zone: response.sales?.zone,
      zoneName: response.sales?.zoneName,
    },
    segment: response.segment,
    segmentName: response.segmentName,
    subSegment: response.subSegment,
    subsegmentName: response.subsegmentName,
    supervision: response.supervision,
    taxId: response.taxId,
  };
}
