import { AgentKpiDomain } from "@/domains/AgentKpiDomain";
import { getAgentKpi } from "@/services/agentKpi/getAgentKpi/GetAgentKpiService";

import { getAgentKpiConvert } from "./converters/GetAgentKpiConvert";

export const getAgentKpiUseCase = async (): Promise<AgentKpiDomain> => {
  const response = await getAgentKpi();

  return getAgentKpiConvert(response);
};
