import { useIntl } from "react-intl";

import { Phone } from "@hexa-ui/icons";

import { useStartAutoDialer } from "./hooks/useStartAutoDialer";
import * as Styled from "./StartAutoDialer.styles";

export default function StartAutoDialer() {
  const { formatMessage } = useIntl();
  const { handleStartAutoDialer, isAutoDialerLoading, isAutoDialerDisabled } =
    useStartAutoDialer();

  const title = formatMessage({ id: "StartCall.GetNextPoc.GO_TO_NEXT_POC" });

  if (isAutoDialerLoading) {
    return <Loading />;
  }

  return (
    <Styled.AutoDialerButton
      icon={Phone}
      onClick={handleStartAutoDialer}
      trailing
      disabled={isAutoDialerDisabled}
      data-testid="auto-dialer-button"
    >
      {title}
    </Styled.AutoDialerButton>
  );
}

function Loading() {
  return (
    <Styled.LoadingButton
      data-testid="auto-dialer-loading"
      icon={Phone}
      trailing
      isLoading
    >
      Loading
    </Styled.LoadingButton>
  );
}
