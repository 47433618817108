import { useIntl } from "react-intl";

import { Heading } from "@hexa-ui/components";

import { useToplineUplift } from "./hooks/useToplineUplift";
import * as Styled from "./ToplineUplift.styles";

export const ToplineUplift = (): JSX.Element => {
  const { formatMessage } = useIntl();

  const {
    daily: { formattedComplete, formattedGap, percent, target },
  } = useToplineUplift();

  const roundedPercent = Math.round(percent);
  const roundedTarget = Math.round(target);

  return (
    <Styled.Container data-testid={`${ToplineUplift.name}`}>
      <Styled.SubContainer>
        <Styled.ValueDescription>
          <Heading size="H2" data-testid={`${ToplineUplift.name}-uplift-value`}>
            {roundedPercent}%
          </Heading>
          <Styled.Label>
            {formatMessage({
              id: "AgentKPIs.DailyKPIs.ToplineUplift.UPLIFT_TEXT",
            })}
          </Styled.Label>
        </Styled.ValueDescription>
        <Styled.ValueDescription>
          <Heading size="H2" data-testid={`${ToplineUplift.name}-target-value`}>
            {roundedTarget}%
          </Heading>
          <Styled.Label>
            {formatMessage({
              id: "AgentKPIs.DailyKPIs.ToplineUplift.TARGET_TEXT",
            })}
          </Styled.Label>
        </Styled.ValueDescription>
      </Styled.SubContainer>

      <Styled.ColumnDivider />

      <Styled.SubContainer>
        <Styled.ValueDescription>
          <Styled.CompleteField
            size="H2"
            data-testid={`${ToplineUplift.name}-completed-uplift-value`}
          >
            {formattedComplete}
          </Styled.CompleteField>
          <Styled.Label>
            {formatMessage({
              id: "AgentKPIs.DailyKPIs.ToplineUplift.UPLIFT_TEXT",
            })}
          </Styled.Label>
        </Styled.ValueDescription>
        <Styled.ValueDescription>
          <Styled.GapField
            size="H2"
            data-testid={`${ToplineUplift.name}-total-gap-value`}
          >
            {formattedGap}
          </Styled.GapField>
          <Styled.Label>
            {formatMessage({
              id: "AgentKPIs.DailyKPIs.ToplineUplift.TOTAL_GAP_TEXT",
            })}
          </Styled.Label>
        </Styled.ValueDescription>
      </Styled.SubContainer>
    </Styled.Container>
  );
};
