import { tokens } from "@bees-grow-shared/theme";
import { TextButton } from "@hexa-ui/components";
import styled from "styled-components";

export const StopAutoDialer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  font-weight: ${tokens.fonts["font-weight"].medium};
  line-height: ${tokens.measure["line-height"]["lineheight-6"]};
  max-height: ${tokens.measure["line-height"]["lineheight-7"]};
  color: ${tokens.color.foundation.txt.secondary};
`;

export const StopAutoDialerButton = styled(TextButton)`
  margin-left: 10px;
  color: ${tokens.color.primitive.solid.blue["blue-70"]};
`;
