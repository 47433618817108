import { AgentKpiDomain } from "@/domains/AgentKpiDomain";

export interface AgentKpiState extends AgentKpiDomain {
  isLoading: boolean;
  error: boolean;
  done: boolean;
}

export const agentsKpiInitialState: AgentKpiState = {
  daily: {
    calls: {
      completed: 0,
      target: 0,
      percent: 0,
    },
    uplift: {
      completed: 0,
      target: 0,
      total: 0,
      gap: 0,
      percent: 0,
    },
    hr: {
      completed: 0,
      target: 0,
      percent: 0,
    },
    aht: {
      completed: "0:00",
      target: "0:00",
    },
  },
  monthly: {
    month: "",
    calls: {
      completed: 0,
      target: 0,
      percent: 0,
    },
    uplift: {
      completed: 0,
      target: 0,
      total: 0,
      gap: 0,
      percent: 0,
    },
    hr: {
      completed: 0,
      target: 0,
      percent: 0,
    },
    aht: {
      completed: "0:00",
      target: "0:00",
    },
  },
  isLoading: false,
  error: false,
  done: false,
};
