import { createStore } from "effector";

import { getAccounts, resetAccounts } from "./AccountVendorsEvents";
import {
  accountSearchInitialState,
  AccountVendorsState,
} from "./AccountVendorsState";

const AccountVendorsStore = createStore(accountSearchInitialState)
  .on(getAccounts, (state: AccountVendorsState): AccountVendorsState => {
    return {
      ...state,
      isLoading: true,
      done: false,
      error: false,
    };
  })
  .on(
    getAccounts.fail,
    (state: AccountVendorsState, { error }): AccountVendorsState => ({
      ...state,
      isLoading: false,
      done: true,
      error: true,
      errorMessage: error.message,
    }),
  )
  .on(
    getAccounts.done,
    (state: AccountVendorsState, { result }): AccountVendorsState => {
      return {
        ...state,
        ...result,
        isLoading: false,
        done: true,
        error: false,
        errorMessage: undefined,
      };
    },
  )
  .on(resetAccounts, () => {
    return accountSearchInitialState;
  });

export default AccountVendorsStore;
