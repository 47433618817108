import { createStore } from "effector";

import { getAgentKpi } from "./AgentKpiEvents";
import { AgentKpiState, agentsKpiInitialState } from "./AgentKpiState";

const AgentKpiStore = createStore(agentsKpiInitialState)
  .on(getAgentKpi, (state: AgentKpiState): AgentKpiState => {
    return {
      ...state,
      isLoading: true,
      done: false,
      error: false,
    };
  })
  .on(
    getAgentKpi.fail,
    (state: AgentKpiState): AgentKpiState => ({
      ...state,
      isLoading: false,
      done: true,
      error: true,
    }),
  )
  .on(getAgentKpi.done, (state: AgentKpiState, { result }): AgentKpiState => {
    return {
      ...state,
      ...result,
      isLoading: false,
      done: true,
      error: false,
    };
  });

export default AgentKpiStore;
