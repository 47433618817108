import { tokens } from "@bees-grow-shared/theme";
import { SkeletonLoader } from "@hexa-ui/components";

import { KpiCard } from "../dailyKpi/DailyKPI.styles";
import {
  Container as ChildContainer,
  ColumnDivider,
} from "../genericKPI/components/percentCompleted/PercentCompleted.styles";
import * as Styled from "../genericKPI/GenericKPI.styles";

export const KPICardSkeleton = (): JSX.Element => {
  return (
    <KpiCard
      elevated={"small"}
      border={"medium"}
      data-testid={KPICardSkeleton.name}
    >
      <Styled.Container data-testid={`${KPICardSkeleton.name}-container`}>
        <Styled.Info data-testid={`${KPICardSkeleton.name}-info`}>
          <SkeletonLoader
            height={tokens.measure["font-size"]["fontsize-3"]}
            width="86px"
          />
          <SkeletonLoader
            height={tokens.measure["font-size"]["fontsize-2"]}
            width="214px"
          />
        </Styled.Info>
        <Styled.Content data-testid={`${KPICardSkeleton.name}-content`}>
          <ChildContainer
            data-testid={`${KPICardSkeleton.name}-child-container`}
          >
            <SkeletonLoader
              height={tokens.measure["font-size"]["fontsize-7"]}
              width="70px"
            />
            <ColumnDivider
              data-testid={`${KPICardSkeleton.name}-column-divider`}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
              data-testid={`${KPICardSkeleton.name}-skeleton-wrapper`}
            >
              <SkeletonLoader
                height={tokens.measure["font-size"]["fontsize-5"]}
                width="60px"
              />
              <SkeletonLoader
                height={tokens.measure["font-size"]["fontsize-2"]}
                width="84px"
              />
            </div>
          </ChildContainer>
          <Styled.Target
            style={{ gap: "0.5rem" }}
            data-testid={`${KPICardSkeleton.name}-target`}
          >
            <SkeletonLoader
              height={tokens.measure["font-size"]["fontsize-5"]}
              width="60px"
            />
            <SkeletonLoader
              height={tokens.measure["font-size"]["fontsize-2"]}
              width="84px"
            />
          </Styled.Target>
        </Styled.Content>
      </Styled.Container>
    </KpiCard>
  );
};
