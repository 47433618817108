import styled, { css, keyframes } from "styled-components";

const visible = css`
  visibility: visible;
  opacity: 1;
`;

const hidden = css`
  visibility: hidden;
  opacity: 0;
`;

export const fadeOut = keyframes`
  from {
    ${visible}
  }
  to {
    ${hidden}
  }
`;

export const fadeIn = keyframes`
  from {
    ${hidden}
  }
  to {
    ${visible}
  }
`;

export const fadeOutAnimation = css`
  animation: ${fadeOut} 800ms ease-out forwards;
`;

export const fadeInAnimation = css`
  animation: ${fadeIn} 800ms ease-out forwards;
`;

export const fadeAnimation = (animate: boolean) => {
  if (animate === true)
    return css`
      ${fadeOutAnimation};
    `;
  if (animate === false)
    return css`
      ${fadeInAnimation}
    `;
  return css``;
};

export const fadeReverseAnimation = (animate: boolean) => {
  if (animate === true)
    return css`
      ${fadeInAnimation};
    `;
  if (animate === false)
    return css`
      ${fadeOutAnimation}
    `;
  return css`
    ${hidden}
  `;
};

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
