import MessageMap from "../i18n.d";

const enUS: MessageMap = {
  AgentKPIs: {
    Header: {
      TITLE: "Calls and KPIs",
      UPDATE_BUTTON: "Update",
    },
    UnableLoadPage: {
      TITLE: "Unable to load page",
      DESCRIPTION: "Try reloading the page",
      ACTION: "Reload",
    },
    MonthlyKPIs: {
      TITLE: "Monthly results",
      SUBTITLE: "Total performance",
      HitRate: {
        TITLE: "Hit rate",
        COMPLETED_TEXT: "Effective calls",
        TARGET_TEXT: "Total calls",
      },
      CallList: {
        TITLE: "Calls",
        COMPLETED_TEXT: "Completed calls",
        TARGET_TEXT: "Target",
      },
      AverageHandleTime: {
        TITLE: "Average handle time",
        COMPLETED_TEXT: "Time on call",
        TARGET_TEXT: "Target",
      },
      ToplineUplift: {
        TITLE: "Topline uplift",
        UPLIFT_TEXT: "Uplift",
        TARGET_TEXT: "Target",
      },
    },
    DailyKPIs: {
      TITLE: "Daily results",
      HitRate: {
        TITLE: "Hit rate",
        DESCRIPTION: "Calls answered by the POC.",
        COMPLETED_TEXT: "Effective calls",
        TARGET_TEXT: "Total calls",
      },
      CallList: {
        TITLE: "Calls",
        DESCRIPTION: "Percentage of completed calls.",
        COMPLETED_TEXT: "Completed calls",
        TARGET_TEXT: "Target",
      },
      AverageHandleTime: {
        TITLE: "Average handle time",
        DESCRIPTION: "Average duration of a POC call.",
        COMPLETED_TEXT: "Time on call",
        TARGET_TEXT: "Target",
      },
      ToplineUplift: {
        TITLE: "Topline uplift",
        DESCRIPTION: "Increase in sales performance.",
        UPLIFT_TEXT: "Uplift",
        TARGET_TEXT: "Target",
        TOTAL_GAP_TEXT: "Total gap",
      },
    },
  },
  SearchByPoc: {
    SearchField: {
      PLACEHOLDER: "Search by POC ID",
    },
    EmptySearch: {
      DESCRIPTION: "No POCs found. Review the ID entered or try another one.",
    },
    Content: {
      TITLE: "Confirm seller",
      DESCRIPTION: "Confirm the seller that you represent in this call.",
      SELLER_HEADER: "Seller",
      ACCOUNT_HEADER: "POC",
    },
    ActionButton: {
      LABEL: "Go to POC",
    },
  },
  Errors: {
    GENERIC_ERROR: "Something went wrong. Try again later.",
  },
  StartCall: {
    OpenCall: {
      TITLE: "Resume last client",
      Error: {
        DESCRIPTION: "Unable to load last client",
        RETRY_TITLE: "Try again",
      },
    },
    GetNextPoc: {
      CALL_MANUALLY: "Call manually",
      GO_TO_NEXT_POC: "Go to next POC",
      CustomErrors: {
        GROUP_WITHOUT_MISSION_PRIORITY:
          "There are no missions assigned to you. Contact your supervisor for assistance.",
        NO_POCS_IN_CALL_LIST: "Your call list is empty.",
      },
    },
    AutoDialer: {
      CustomErrors: {
        CTI_NOT_CONNECTED:
          "Something went wrong. Contact the manager if the issue persists.",
        CTI_CONNECTED: "CTI connected successfully. Wait for a call.",
      },
      StopCalling: {
        TEXT: "Calling the POC. Make sure you are logged in auto dialer and marked as available.",
        BUTTON: "Stop calling",
        Modal: {
          TITLE: "Stop calling the POC?",
          DESCRIPTION:
            "Make sure you are unavailable on autodialer, or it will keep calling the POCs.",
          GO_BACK_BUTTON: "Go back",
          STOP_CALLING_BUTTON: "Stop Calling",
        },
      },
      VendorSelectorModal: {
        CANCEL_BUTTON: "Cancel",
      },
    },
  },
};

export default enUS;
