import { Divider } from "@hexa-ui/components";

import {
  ColumnDivider,
  Container as PercentContainer,
} from "../genericKPI/components/percentCompleted/PercentCompleted.styles";
import { Container, Info } from "../genericKPI/GenericKPI.styles";
import {
  SkeletonBottom,
  SkeletonGap,
  SkeletonLoaderBottom,
  SkeletonLoaderCompleted,
  SkeletonLoaderCompletedText,
  SkeletonLoaderInfo,
  SkeletonLoaderTarget,
  SkeletonLoaderTargetText,
  SkeletonPadding,
} from "./KPICardSkeleton.styles";

export const MonthlyKPICardSkeleton = () => (
  <Container data-testid="kpi-generic-card" fit>
    <Divider />
    <Info>
      <SkeletonLoaderInfo width="195px" />
    </Info>
    <SkeletonPadding>
      <PercentContainer>
        <SkeletonBottom>
          <SkeletonLoaderBottom />
        </SkeletonBottom>
        <ColumnDivider />
        <SkeletonGap>
          <SkeletonLoaderTarget />
          <SkeletonLoaderTargetText />
        </SkeletonGap>
      </PercentContainer>
      <SkeletonGap style={{ alignItems: "end" }}>
        <SkeletonLoaderCompleted />
        <SkeletonLoaderCompletedText />
      </SkeletonGap>
    </SkeletonPadding>
  </Container>
);
