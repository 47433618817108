import { tokens } from "@bees-grow-shared/theme";
import { SkeletonLoader } from "@hexa-ui/components";
import styled from "styled-components";

export const SkeletonLoaderInfo = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-3"]};
  width: 195px;
`;

export const SkeletonLoaderBottom = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-7"]};
  width: 70px;
`;

export const SkeletonLoaderTarget = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-5"]};
  width: 60px;
`;

export const SkeletonLoaderTargetText = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-3"]};
  width: 84px;
`;

export const SkeletonLoaderCompleted = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-5"]};
  width: 60px;
`;

export const SkeletonLoaderCompletedText = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-3"]};
  width: 84px;
`;

export const SkeletonGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-2"]};
  padding: 0.25rem 0;
`;

export const SkeletonPadding = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.625rem 0;
`;

export const SkeletonBottom = styled.div`
  display: flex;
  align-items: flex-end;
`;
