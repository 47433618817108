import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useToast } from "@bees-grow-shared/business-components";
import {
  agentCallSharedService,
  autoDialerSharedService,
  ctiSharedService,
  PocCallOrigin,
  userConfigSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";

import { ACCOUNT_PATH, REDIRECT_NOT_REQUIRED } from "@/config/constants";
import { getAccounts } from "@/stores/account/AccountVendorsEvents";
import { ValidateAutoDialerUseCase } from "@/useCases/autoDialer/ValidateAutoDialerUseCase/ValidateAutoDialerUseCase";

interface UseStartAutoDialerProps {
  handleStartAutoDialer: () => void;
  isAutoDialerLoading: boolean;
  isAutoDialerDisabled: boolean;
}

export function useStartAutoDialer(): UseStartAutoDialerProps {
  const toast = useToast();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const agentCallService = agentCallSharedService();
  const ctiService = ctiSharedService();
  const userConfigService = userConfigSharedService();
  const autoDialerService = autoDialerSharedService();

  const {
    startCall: {
      isLoading,
      error: isStartCallError,
      errorMessage: startCallErrorMessage,
    },
    pocCallOrigin,
  } = useSharedUnit(agentCallService);

  const { isConnected: isCTIConnected } = useSharedUnit(ctiService);

  const { groupId } = useSharedUnit(userConfigService);

  const { accountIdAutoDialer } = useSharedUnit(autoDialerService);

  const handleStartAutoDialer = useCallback(() => {
    ctiService.open();
    agentCallService.setStartCallState({
      startCall: { isLoading: true },
      pocCallOrigin: PocCallOrigin.AUTO_DIALER,
    });
  }, [agentCallService, ctiService]);

  const isAutoDialer = useMemo(
    () => pocCallOrigin === PocCallOrigin.AUTO_DIALER,
    [pocCallOrigin],
  );

  const isAutoDialerLoading = useMemo(
    () => isLoading && isAutoDialer,
    [isLoading, isAutoDialer],
  );

  const isAutoDialerDisabled = useMemo(
    () => isLoading && !isAutoDialer,
    [isLoading, isAutoDialer],
  );

  useEffect(() => {
    if (accountIdAutoDialer) {
      getAccounts({ accountId: accountIdAutoDialer, groupId }).then(() => {
        ValidateAutoDialerUseCase().then((result) => {
          if (result !== REDIRECT_NOT_REQUIRED) {
            navigate(ACCOUNT_PATH);
          }
        });
      });
    }
  }, [accountIdAutoDialer, groupId, navigate]);

  useEffect(() => {
    if (isStartCallError) {
      toast({
        showCloseButton: true,
        type: "error",
        message: startCallErrorMessage,
      });
    }
  }, [isStartCallError, startCallErrorMessage, toast]);

  useEffect(() => {
    if (isCTIConnected === false && isAutoDialerLoading) {
      toast({
        showCloseButton: true,
        type: "error",
        message: formatMessage({
          id: "StartCall.AutoDialer.CustomErrors.CTI_NOT_CONNECTED",
        }),
      });
    }

    if (isCTIConnected && isAutoDialerLoading && !isStartCallError) {
      toast({
        showCloseButton: true,
        type: "success",
        message: formatMessage({
          id: "StartCall.AutoDialer.CustomErrors.CTI_CONNECTED",
        }),
      });
    }
  }, [
    formatMessage,
    isAutoDialerLoading,
    isCTIConnected,
    isStartCallError,
    toast,
  ]);

  return {
    handleStartAutoDialer,
    isAutoDialerLoading,
    isAutoDialerDisabled,
  };
}
