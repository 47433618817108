import { AccountVendorsDomain } from "@/domains/AccountVendorsDomain";

export interface AccountVendorsState extends AccountVendorsDomain {
  isLoading: boolean;
  error: boolean;
  errorMessage: string;
  done: boolean;
}

export const accountSearchInitialState: AccountVendorsState = {
  accountVendors: undefined,
  isLoading: false,
  error: false,
  errorMessage: undefined,
  done: false,
};
