import { tokens } from "@bees-grow-shared/theme";
import styled from "styled-components";

import {
  headerResponsiveStyles,
  userInfoSectionResponsiveStyles,
} from "@/components/Header/components/startCall/components/searchByPoc/SearchByPoc.styles";
import { fadeAnimation } from "@/pages/homePage/HomePage.styles";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;

  ${headerResponsiveStyles}
`;

export const UserInfoSection = styled.div<{ faded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  z-index: 10;

  ${(props) => fadeAnimation(props.faded)}

  ${userInfoSectionResponsiveStyles}
`;

export const ImageSection = styled.div`
  display: flex;
  width: 3rem;
  height: 3rem;
  border-radius: ${tokens.measure["border-radius"]["borderradius-full"]};
  border: 3px solid
    ${tokens.color.foundation.border.on.elevated["high-contrast"]};
  box-shadow: ${tokens.shadow["elevation-2"]};
  overflow: hidden;

  > img[src=""] {
    display: none;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: ${tokens.measure["border-radius"]["borderradius-full"]};
`;
