import MessageMap from "../i18n.d";

const es419: MessageMap = {
  AgentKPIs: {
    Header: {
      TITLE: "Llamadas y KPIs",
      UPDATE_BUTTON: "Actualizar",
    },
    UnableLoadPage: {
      TITLE: "No se puede cargar la página",
      DESCRIPTION: "Intenta volver a cargar la página",
      ACTION: "Recargar",
    },
    MonthlyKPIs: {
      TITLE: "Resultados mensuales",
      SUBTITLE: "Rendimiento",
      HitRate: {
        TITLE: "Contactabilidad",
        COMPLETED_TEXT: "Llamadas efectivas",
        TARGET_TEXT: "Total de llamadas",
      },
      CallList: {
        TITLE: "Llamadas",
        COMPLETED_TEXT: "Llamadas completadas",
        TARGET_TEXT: "Objetivo",
      },
      AverageHandleTime: {
        TITLE: "Tiempo promedio de manejo",
        COMPLETED_TEXT: "Tiempo de guardia",
        TARGET_TEXT: "Objetivo",
      },
      ToplineUplift: {
        TITLE: "Topline recuperado",
        UPLIFT_TEXT: "Recuperado",
        TARGET_TEXT: "Objetivo",
      },
    },
    DailyKPIs: {
      TITLE: "Resultados diarios",
      HitRate: {
        TITLE: "Contactabilidad",
        DESCRIPTION: "Llamadas atendidas por el POC.",
        COMPLETED_TEXT: "Llamadas efectivas",
        TARGET_TEXT: "Total de llamadas",
      },
      CallList: {
        TITLE: "Llamadas",
        DESCRIPTION: "Porcentaje de llamadas completadas.",
        COMPLETED_TEXT: "Llamadas completadas",
        TARGET_TEXT: "Objetivo",
      },
      AverageHandleTime: {
        TITLE: "Tiempo promedio de manejo",
        DESCRIPTION: "Duración promedio de una llamada POC.",
        COMPLETED_TEXT: "Tiempo de guardia",
        TARGET_TEXT: "Objetivo",
      },
      ToplineUplift: {
        TITLE: "Topline recuperado",
        DESCRIPTION: "Incremento del rendimiento de ventas.",
        UPLIFT_TEXT: "Recuperado",
        TARGET_TEXT: "Objetivo",
        TOTAL_GAP_TEXT: "Gap total",
      },
    },
  },
  SearchByPoc: {
    SearchField: {
      PLACEHOLDER: "Buscar por ID de POC",
    },
    EmptySearch: {
      DESCRIPTION:
        "No se encontraron POCs. Verifique el ID ingresado o intente con otro.",
    },
    Content: {
      TITLE: "Confirmar vendedor",
      DESCRIPTION: "Confirme el vendedor que usted representa en esta llamada.",
      SELLER_HEADER: "Vendedor",
      ACCOUNT_HEADER: "POC",
    },
    ActionButton: {
      LABEL: "Ir a POC",
    },
  },
  Errors: {
    GENERIC_ERROR: "Algo salió mal. Intenta de nuevo más tarde.",
  },
  StartCall: {
    OpenCall: {
      TITLE: "Reanudar último cliente",
      Error: {
        DESCRIPTION: "No se puede cargar el último cliente",
        RETRY_TITLE: "Intentar de nuevo",
      },
    },
    GetNextPoc: {
      CALL_MANUALLY: "Llamada manual",
      GO_TO_NEXT_POC: "Ir al siguiente POC",
      CustomErrors: {
        GROUP_WITHOUT_MISSION_PRIORITY:
          "No hay misiones asignadas para ti. Comuníquese con su supervisor para obtener ayuda.",
        NO_POCS_IN_CALL_LIST: "Su lista de llamadas está agotada.",
      },
    },
    AutoDialer: {
      CustomErrors: {
        CTI_NOT_CONNECTED:
          "Algo salió mal. Comuníquese con el administrador si el problema persiste.",
        CTI_CONNECTED: "CTI se conectó exitosamente. Espere una llamada.",
      },
      StopCalling: {
        TEXT: "Llamando al POC. Asegúrese de estar conectado al marcador automático y marcado como disponible.",
        BUTTON: "Detener llamada",
        Modal: {
          TITLE: "¿Dejar de llamar al POC?",
          DESCRIPTION:
            "Asegúrese de no estar disponible en el marcador automático o seguirá llamando a los POC.",
          GO_BACK_BUTTON: "Volver",
          STOP_CALLING_BUTTON: "Para de llamar",
        },
      },
      VendorSelectorModal: {
        CANCEL_BUTTON: "Cancelar",
      },
    },
  },
};

export default es419;
