import {
  agentCallSharedService,
  autoDialerSharedService,
  PocCallOrigin,
} from "@bees-grow-shared/services";

import { AccountVendorsDomain } from "@/domains/AccountVendorsDomain";
import { intl } from "@/i18n";
import { getAccounts } from "@/services/account/getAccounts/GetAccountsService";

import { getAccountsConvert } from "./converters/GetAccountsConverter";

interface GetAccountsProps {
  accountId: string;
  groupId: string;
}

export const getAccountsUseCase = async ({
  accountId,
  groupId,
}: GetAccountsProps): Promise<AccountVendorsDomain> => {
  const autoDialerService = autoDialerSharedService();
  const { pocCallOrigin } = agentCallSharedService().getStoreValue();

  try {
    const response = await getAccounts({
      query: { accountId },
      headers: { groupId },
    });

    return getAccountsConvert(response);
  } catch {
    if (pocCallOrigin === PocCallOrigin.AUTO_DIALER) {
      autoDialerService.setAutoDialerState({ accountIdAutoDialer: "" });

      throw new Error(
        intl.formatMessage({
          id: "StartCall.AutoDialer.CustomErrors.CTI_NOT_CONNECTED",
        }),
      );
    }

    throw new Error(
      intl.formatMessage({
        id: "Errors.GENERIC_ERROR",
      }),
    );
  }
};
