import { createEffect, createEvent } from "effector";

import { AccountVendorsDomain } from "@/domains/AccountVendorsDomain";
import { getAccountsUseCase } from "@/useCases/account/getAccounts/GetAccountsUseCase";

interface GetAccountsProps {
  accountId: string;
  groupId: string;
}

export const getAccounts = createEffect({
  async handler(props: GetAccountsProps): Promise<AccountVendorsDomain> {
    return await getAccountsUseCase(props);
  },
});

export const resetAccounts = createEvent("resetAccounts");
