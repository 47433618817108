import { accountSharedService, AgentCall } from "@bees-grow-shared/services";

import { getOpenCall } from "@/services/agentCall/getOpenCall/GetOpenCallService";
import { getAccountsInfoUseCase } from "@/useCases/account/getAccountsInfo/GetAccountsInfoUseCase";

import { getOpenCallConvert } from "./converters/GetOpenCallConvert";

export async function GetOpenCallUseCase(): Promise<AgentCall> {
  const accountService = accountSharedService();

  const openCallResponse = await getOpenCall();

  const hasOpenCall = !!openCallResponse.callId;
  if (hasOpenCall) {
    const accountInfoResponse = await getAccountsInfoUseCase({
      accountId: openCallResponse.accountId,
      vendorId: openCallResponse.vendorId,
    });

    accountService.setAccount(accountInfoResponse);
  }

  return getOpenCallConvert(openCallResponse);
}
