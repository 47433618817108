import { Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 0.5rem;
`;

export const Option = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    div {
      label {
        font-weight: 500;
      }
      button {
        border-width: 2px;
        border-color: black;
      }
    }
    p {
      font-weight: 500;
    }
  }
`;

export const OptionAccountName = styled(Paragraph)`
  align-content: center;
`;

export const OptionsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;
