import { axiosInstance } from "@bees-grow-shared/services";

import { GROW_AGENT_CALL_SERVICE_ENDPOINTS } from "../endpoints";
import { StartCallResponse } from "../model/StartCallResponse";

interface GetNextPocProps {
  ctiEnabled: boolean;
}

export async function getNextPoc({
  ctiEnabled,
}: GetNextPocProps): Promise<StartCallResponse> {
  const { data } = await axiosInstance.post<StartCallResponse>(
    GROW_AGENT_CALL_SERVICE_ENDPOINTS.GET_NEXT_POC(),
    { ctiEnabled },
  );

  return data;
}
