import { axiosInstance } from "@bees-grow-shared/services";

import { GROW_AGENT_KPI_SERVICE_ENDPOINTS } from "../endpoints";
import { AgentKpiResponse } from "./model/GetAgentKpiResponse";

export async function getAgentKpi(): Promise<AgentKpiResponse> {
  const response = await axiosInstance.get<AgentKpiResponse>(
    GROW_AGENT_KPI_SERVICE_ENDPOINTS.GET_AGENTS_KPIS(),
  );

  return response.data;
}
