export function ArrowLeftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#141414"
        d="M9.796.33c.405.405.436 1.043.093 1.485l-.093.106-5.954 5.954h13.033a1.125 1.125 0 01.131 2.242l-.131.008H3.842l5.953 5.954c.406.406.437 1.044.094 1.485l-.094.107a1.125 1.125 0 01-1.485.093l-.106-.093L.33 9.794A1.122 1.122 0 010 9.01V9a1.206 1.206 0 01.001-.054 1.12 1.12 0 01.235-.636c.03-.037.06-.072.094-.106L8.205.33c.439-.44 1.151-.44 1.59 0z"
      ></path>
    </svg>
  );
}

export default ArrowLeftIcon;
