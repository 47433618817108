import { Heading, Paragraph } from "@hexa-ui/components";

import * as Styled from "./GenericKPI.styles";

interface GenericKPIProps {
  title: string;
  description?: string;
  targetValue?: string | number;
  targetCaption?: string;
  showTarget?: boolean;
  children: JSX.Element;
  fit?: boolean;
}
export const GenericKPI = ({
  title,
  description,
  targetValue,
  targetCaption,
  showTarget = true,
  children,
  fit = false,
}: GenericKPIProps): JSX.Element => {
  return (
    <Styled.Container data-testid="kpi-generic-card" fit={fit}>
      <Styled.Info>
        {fit ? (
          <Paragraph weight="medium">{title}</Paragraph>
        ) : (
          <Heading size="H5">{title}</Heading>
        )}
        {description && (
          <Styled.InfoDescription size="small" weight="normal">
            {description}
          </Styled.InfoDescription>
        )}
      </Styled.Info>
      <Styled.Content fit={fit}>
        {children}
        {showTarget ? (
          <Styled.Target data-testid="target-generic-card">
            <Styled.TargetValue size="H4">
              {typeof targetValue === "string"
                ? targetValue
                : Math.round(targetValue)}
            </Styled.TargetValue>
            <Styled.TargetName>{targetCaption}</Styled.TargetName>
          </Styled.Target>
        ) : null}
      </Styled.Content>
    </Styled.Container>
  );
};
