import { useCallback } from "react";

import { autoDialerSharedService } from "@bees-grow-shared/services";

interface UseStopAutoDialerProps {
  handleOpenStopCallingModal: () => void;
}

export function useStopAutoDialer(): UseStopAutoDialerProps {
  const autoDialerService = autoDialerSharedService();

  const handleOpenStopCallingModal = useCallback(() => {
    autoDialerService.setAutoDialerState({ showStopCallingModal: true });
  }, [autoDialerService]);

  return {
    handleOpenStopCallingModal,
  };
}
