import React, { createContext, ReactNode, useMemo, useState } from "react";

export interface ExpandableSearchContextType {
  startCallButtonsWidth: number;
  hasExpandedSearch: boolean;
  expandSearch: () => void;
  shrinkSearch: () => void;
  setStartCallButtonsWidth: (size: number) => void;
}

interface SearchByPocProviderProps {
  children: ReactNode;
}

export const ExpandableSearchContext = createContext(
  {} as ExpandableSearchContextType,
);

export const ExpandableSearchProvider: React.FC<SearchByPocProviderProps> = ({
  children,
}) => {
  const [hasExpandedSearch, setHasExpandedSearch] =
    useState<boolean>(undefined);
  const [startCallButtonsWidth, setStartCallButtonsWidth] =
    useState<number>(undefined);

  const expandSearch = () => setHasExpandedSearch(true);
  const shrinkSearch = () => setHasExpandedSearch(false);

  const contextValue = useMemo(
    () => ({
      startCallButtonsWidth,
      hasExpandedSearch,
      expandSearch,
      shrinkSearch,
      setStartCallButtonsWidth,
    }),
    [hasExpandedSearch, startCallButtonsWidth],
  );

  return (
    <ExpandableSearchContext.Provider value={contextValue}>
      {children}
    </ExpandableSearchContext.Provider>
  );
};
