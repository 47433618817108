import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { useIntl } from "react-intl";

import { useToast } from "@bees-grow-shared/business-components";
import {
  agentCallSharedService,
  ctiSharedService,
  PocCallOrigin,
  useFeatureToggleEnabledByOrgId,
  useSharedUnit,
} from "@bees-grow-shared/services";

import {
  GROW_PORTAL_AUTODIALER,
  GROW_PORTAL_CTI,
} from "@/config/featureToggles";
import { ExpandableSearchContext } from "@/contexts/expandableSearch/ExpandableSearchContext";
import { GetOpenCallUseCase } from "@/useCases/agentCall/getOpenCall/GetOpenCallUseCase";

interface UseStartCallProps {
  isOpenCallLoading: boolean;
  hasOpenCallError: boolean;
  hasOpenCall: boolean;
  buttonsContainerRef: React.RefObject<HTMLDivElement>;
  isCTIEnabled: boolean;
  isAutoDialerEnabled: boolean;
  isAutoDialerConnected: boolean;
}

export function useStartCall(): UseStartCallProps {
  const toast = useToast();
  const { formatMessage } = useIntl();
  const agentCallService = agentCallSharedService();
  const ctiService = ctiSharedService();
  const buttonsContainerRef = useRef<HTMLDivElement>(null);

  const { isConnected: isCTIConnected } = useSharedUnit(ctiService);

  const { startCallButtonsWidth, setStartCallButtonsWidth } = useContext(
    ExpandableSearchContext,
  );
  const { isEnabled: isCTIEnabled } =
    useFeatureToggleEnabledByOrgId(GROW_PORTAL_CTI);

  const { isEnabled: isAutoDialerEnabled } = useFeatureToggleEnabledByOrgId(
    GROW_PORTAL_AUTODIALER,
  );

  const {
    openCall: { isLoading: isOpenCallLoading, error: hasOpenCallError },
    startCall: { isLoading: isStartCallLoading },
    callId,
    pocCallOrigin,
  } = useSharedUnit(agentCallService);

  const getOpenCall = useCallback(() => {
    agentCallService.setOpenCall(GetOpenCallUseCase());
  }, [agentCallService]);

  useEffect(() => {
    if (!callId) {
      getOpenCall();
    }
  }, [callId, getOpenCall]);

  useEffect(() => {
    if (hasOpenCallError) {
      toast({
        showCloseButton: false,
        type: "error",
        message: formatMessage({
          id: "StartCall.OpenCall.Error.DESCRIPTION",
        }),
        actions: [
          {
            action: getOpenCall,
            name: formatMessage({
              id: "StartCall.OpenCall.Error.RETRY_TITLE",
            }),
          },
        ],
      });
    }
  }, [formatMessage, getOpenCall, hasOpenCallError, toast]);

  useLayoutEffect(() => {
    if (buttonsContainerRef.current && !startCallButtonsWidth) {
      setStartCallButtonsWidth(buttonsContainerRef.current.offsetWidth);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonsContainerRef.current, startCallButtonsWidth]);

  const hasOpenCall = useMemo(() => {
    return !!callId;
  }, [callId]);

  const isAutoDialer = useMemo(
    () => pocCallOrigin === PocCallOrigin.AUTO_DIALER,
    [pocCallOrigin],
  );

  const isAutoDialerLoading = useMemo(
    () => isStartCallLoading && isAutoDialer,
    [isStartCallLoading, isAutoDialer],
  );

  const isAutoDialerConnected = useMemo(
    () => isAutoDialerLoading || isCTIConnected,
    [isAutoDialerLoading, isCTIConnected],
  );

  return {
    isOpenCallLoading,
    hasOpenCallError,
    hasOpenCall,
    buttonsContainerRef,
    isCTIEnabled,
    isAutoDialerEnabled,
    isAutoDialerConnected,
  };
}
