import { tokens } from "@bees-grow-shared/theme";
import { Heading, Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: baseline;
  gap: ${tokens.measure.space["spacing-2"]};
`;

export const Header = styled(Heading)`
  align-self: end;
`;

export const Label = styled(Paragraph)`
  color: ${tokens.color.foundation.txt.secondary};
  align-self: end;
`;
