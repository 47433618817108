import { Heading } from "@hexa-ui/components";
import styled from "styled-components";

export const TitleHeading = styled(Heading)`
  margin-bottom: 1.25rem;
`;

export const ModalContainer = styled.div`
  width: 36.875rem;
`;
