import { useIntl } from "react-intl";

import { Phone } from "@hexa-ui/icons";

import * as Styled from "./GoToNextPoc.styles";
import { useGoToNextPoc } from "./hooks/useGoToNextPoc";

export default function GoToNextPoc() {
  const { formatMessage } = useIntl();
  const { isGoToNextPocLoading, handleOnClick, isGoToNextPocDisabled } =
    useGoToNextPoc();

  const title = formatMessage({ id: "StartCall.GetNextPoc.GO_TO_NEXT_POC" });

  if (isGoToNextPocLoading) {
    return <Loading />;
  }

  return (
    <Styled.GoToNextPocButton
      icon={Phone}
      onClick={handleOnClick}
      trailing
      disabled={isGoToNextPocDisabled}
      data-testid="go-to-next-poc-button"
    >
      {title}
    </Styled.GoToNextPocButton>
  );
}

function Loading() {
  return (
    <Styled.LoadingButton
      data-testid="go-to-next-poc-loading"
      icon={Phone}
      trailing
      isLoading
    >
      Loading
    </Styled.LoadingButton>
  );
}
