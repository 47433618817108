import { useIntl } from "react-intl";

import { Heading } from "@hexa-ui/components";
import { useStore } from "effector-react";

import AgentKpiStore from "@/stores/agentKpi/AgentKpiStore";

import { UpdateButton } from "../updateButton/UpdateButton";
import * as Styles from "./HeaderKPI.styles";

export default function HeaderKPI() {
  const { isLoading } = useStore(AgentKpiStore);
  const { formatMessage } = useIntl();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Styles.KpiHeaderContainer data-testid={`${HeaderKPI.name}`}>
      <Heading size="H3" data-testid={`${HeaderKPI.name}-${Heading.name}`}>
        {formatMessage({ id: "AgentKPIs.Header.TITLE" })}
      </Heading>
      <UpdateButton />
    </Styles.KpiHeaderContainer>
  );
}

function Loading() {
  return (
    <Styles.SkeletonContainer data-testid={`${HeaderKPI.name}-${Loading.name}`}>
      <Styles.SkeletonLoaderTitle />
      <Styles.SkeletonLoaderButton />
    </Styles.SkeletonContainer>
  );
}
