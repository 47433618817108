import * as Styled from "./TimeCompleted.styles";

interface TimeCompletedProps {
  achievementCaption: string;
  achievementValue: string;
}

export const TimeCompleted = ({
  achievementValue,
  achievementCaption,
}: TimeCompletedProps): JSX.Element => {
  return (
    <Styled.Container data-testid={`${TimeCompleted.name}-completed`}>
      <Styled.Header>{achievementValue}</Styled.Header>
      <Styled.Label>{achievementCaption}</Styled.Label>
    </Styled.Container>
  );
};
