import { tokens } from "@bees-grow-shared/theme";
import { Card, SkeletonLoader } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-4"]};
`;

export const Cards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${tokens.measure.space["spacing-6"]};
`;

export const Header = styled.div`
  width: inherit;
  display: flex;
  justify-content: space-between;
`;

export const KpiCard = styled(Card)`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
`;

export const Loader = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-6"]};
  width: 11.75rem;
`;
