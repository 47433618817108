import MessageMap from "../i18n.d";

const ptBR: MessageMap = {
  AgentKPIs: {
    Header: {
      TITLE: "Chamadas e KPIs",
      UPDATE_BUTTON: "Atualizar",
    },
    UnableLoadPage: {
      TITLE: "Não foi possível carregar a página",
      DESCRIPTION: "Tente carregar a página novamente",
      ACTION: "Recarregar",
    },
    MonthlyKPIs: {
      TITLE: "Resultados mensais",
      SUBTITLE: "Desempenho",
      HitRate: {
        TITLE: "Hit rate",
        COMPLETED_TEXT: "Chamadas atendidas",
        TARGET_TEXT: "Total de chamadas",
      },
      CallList: {
        TITLE: "Chamadas",
        COMPLETED_TEXT: "Chamadas encerradas",
        TARGET_TEXT: "Meta",
      },
      AverageHandleTime: {
        TITLE: "Tempo médio de atendimento",
        COMPLETED_TEXT: "Tempo em chamada",
        TARGET_TEXT: "Meta",
      },
      ToplineUplift: {
        TITLE: "Topline convertido",
        UPLIFT_TEXT: "Convertido",
        TARGET_TEXT: "Meta",
      },
    },
    DailyKPIs: {
      TITLE: "Resultados diários",
      HitRate: {
        TITLE: "Hit rate",
        DESCRIPTION: "Chamadas atendidas pelo POC.",
        COMPLETED_TEXT: "Chamadas atendidas",
        TARGET_TEXT: "Total de chamadas",
      },
      CallList: {
        TITLE: "Chamadas",
        DESCRIPTION: "Porcentagem de chamadas concluídas.",
        COMPLETED_TEXT: "Chamadas encerradas",
        TARGET_TEXT: "Meta",
      },
      AverageHandleTime: {
        TITLE: "Tempo médio de atendimento",
        DESCRIPTION: "Duração média de uma chamada com a POC.",
        COMPLETED_TEXT: "Tempo em chamada",
        TARGET_TEXT: "Meta",
      },
      ToplineUplift: {
        TITLE: "Topline convertido",
        DESCRIPTION: "Aumento do desempenho de vendas.",
        UPLIFT_TEXT: "Convertido",
        TARGET_TEXT: "Meta",
        TOTAL_GAP_TEXT: "Gap total",
      },
    },
  },
  SearchByPoc: {
    SearchField: {
      PLACEHOLDER: "Buscar por ID de POC",
    },
    EmptySearch: {
      DESCRIPTION:
        "Nenhum POC encontrado. Verifique o ID digitado ou tente outro.",
    },
    Content: {
      TITLE: "Confirmar vendedor",
      DESCRIPTION: "Confirme o vendedor que você representa nesta chamada.",
      SELLER_HEADER: "Vendedor",
      ACCOUNT_HEADER: "POC",
    },
    ActionButton: {
      LABEL: "Ir para POC",
    },
  },
  Errors: {
    GENERIC_ERROR: "Algo deu errado. Tente novamente mais tarde.",
  },
  StartCall: {
    OpenCall: {
      TITLE: "Retomar último cliente",
      Error: {
        DESCRIPTION: "Não foi possível carregar o último cliente",
        RETRY_TITLE: "Tentar novamente",
      },
    },
    GetNextPoc: {
      CALL_MANUALLY: "Chamada manual",
      GO_TO_NEXT_POC: "Ir para a próxima POC",
      CustomErrors: {
        GROUP_WITHOUT_MISSION_PRIORITY:
          "Não há missões atribuídas a você. Contate seu supervisor para assistência.",
        NO_POCS_IN_CALL_LIST: "Sua call list está esgotada.",
      },
    },
    AutoDialer: {
      CustomErrors: {
        CTI_NOT_CONNECTED:
          "Algo deu errado. Contacte o gerente se o problema persistir.",
        CTI_CONNECTED: "CTI conectado com sucesso. Aguarde uma chamada.",
      },
      StopCalling: {
        TEXT: "Chamando o POC. Certifique-se de estar logado no discador automático e marcado como disponível.",
        BUTTON: "Parar chamada",
        Modal: {
          TITLE: "Parar de chamar a POC?",
          DESCRIPTION:
            "Certifique-se de que está indisponível no discador automático, ou continuará chamando a POC.",
          GO_BACK_BUTTON: "Voltar",
          STOP_CALLING_BUTTON: "Parar de chamar",
        },
      },
      VendorSelectorModal: {
        CANCEL_BUTTON: "Cancelar",
      },
    },
  },
};

export default ptBR;
