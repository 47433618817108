import { axiosInstance } from "@bees-grow-shared/services";

import { GROW_ACCOUNT_SEARCH_SERVICE_ENDPOINTS } from "../endpoints";
import { AccountInfoResponse } from "./model/AccountInfoResponse";
import {
  GetAccountsInfoRequestHeaders,
  GetAccountsInfoRequestQuery,
} from "./model/GetAccountsInfoRequest";

interface GetAccountsInfoProps {
  query: GetAccountsInfoRequestQuery;
  headers: GetAccountsInfoRequestHeaders;
}

export async function getAccountsInfo({
  query,
  headers,
}: GetAccountsInfoProps): Promise<AccountInfoResponse> {
  const { accountId } = query;

  const { data } = await axiosInstance.get<AccountInfoResponse>(
    GROW_ACCOUNT_SEARCH_SERVICE_ENDPOINTS.GET_ACCOUNTS_INFO(accountId),
    { headers: { ...headers } },
  );

  return data;
}
