import { tokens } from "@bees-grow-shared/theme";
import styled from "styled-components";

const COLOR_DEFAULT = tokens.color.flavor.rule.txt["general-actions"].default;
const COLOR_HOVER = tokens.color.flavor.rule.txt["general-actions"].hover;
const COLOR_PRESSED = tokens.color.flavor.rule.txt["general-actions"].pressed;

export const Content = styled.div`
  display: flex;
  align-items: center;
  color: ${COLOR_DEFAULT};

  svg path {
    fill: ${COLOR_DEFAULT};
  }

  &:hover {
    color: ${COLOR_HOVER};

    svg path {
      fill: ${COLOR_HOVER};
    }
  }

  &:active {
    color: ${COLOR_PRESSED};
    cursor: default;

    svg path {
      fill: ${COLOR_PRESSED};
    }
  }
`;

export const Text = styled.div`
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 0 0.2rem 0 1.5rem;
`;
