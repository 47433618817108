import { PocCallOrigin } from "@bees-grow-shared/services";

export interface StartCallResponse {
  callId: string;
  startCallDate: string;
  orderInfo: OrderInfo[];
  pocCallOrigin?: PocCallOrigin;
  ctiEnabled: boolean;
  accountId: string;
  vendorAccountId: string;
  vendorId: string;
}

interface OrderInfo {
  orderId: string;
  orderReason: string;
}

export enum ERROR_MESSAGES {
  GROUP_WITHOUT_MISSION_PRIORITY = "GROUP_WITHOUT_MISSION_PRIORITY",
  NO_POCS_IN_CALL_LIST = "NO_POCS_IN_CALL_LIST",
}
