import { useContext } from "react";

import {
  userConfigSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { Heading } from "@hexa-ui/components";

import { ExpandableSearchContext } from "@/contexts/expandableSearch/ExpandableSearchContext";

import StartCall from "./components/startCall/StartCall";
import * as Styled from "./Header.styles";

export default function Header() {
  const userConfigService = userConfigSharedService();
  const { userEmail, orgLogoUrl } = useSharedUnit(userConfigService);

  const { hasExpandedSearch } = useContext(ExpandableSearchContext);

  return (
    <Styled.Container data-testid="header">
      <Styled.UserInfoSection
        faded={hasExpandedSearch}
        data-testid="user-info-section"
      >
        <Styled.ImageSection>
          <Styled.Image
            src={orgLogoUrl}
            alt="logo of the organization"
            data-testid="org-logo-image"
          />
        </Styled.ImageSection>

        <Heading size="H4" data-testid="user-email-heading">
          {userEmail}
        </Heading>
      </Styled.UserInfoSection>

      <StartCall />
    </Styled.Container>
  );
}
