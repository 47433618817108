import styled, { css, keyframes } from "styled-components";

import { fadeIn, fadeOut } from "@/pages/homePage/HomePage.styles";

const erase = keyframes`
  from {
    display: grid;
  }
  to {
    display: none;
  }
`;

const show = keyframes`
  from {
    display: none;
  }
  to {
    display: grid;
  }
`;

const disappearAnimation = (animate: boolean) => {
  if (animate === true)
    return css`
      animation:
        ${fadeOut} 800ms ease-out forwards,
        ${erase} 0ms 800ms forwards;
    `;
  if (animate === false)
    return css`
      animation:
        ${fadeIn} 800ms ease-out forwards,
        ${show} 0ms 0ms forwards;
    `;
  return css``;
};

interface FadableContainer {
  faded?: boolean;
}

export const Container = styled.div<FadableContainer>`
  ${(props) => disappearAnimation(props.faded)}
`;

export const AgentKPIsContainer = styled.div`
  display: grid;
  grid-template-columns: 0.67fr 0.33fr;
  gap: 1.5rem;
  box-sizing: border-box;
  padding-bottom: 1.5rem;
`;

export const ErrorBoundaryContainer = styled.div<FadableContainer>`
  display: flex;
  justify-content: center;

  ${(props) => disappearAnimation(props.faded)}
`;
