import { useContext, useEffect, useMemo, useState } from "react";

import { useUnit } from "effector-react";

import { ExpandableSearchContext } from "@/contexts/expandableSearch/ExpandableSearchContext";
import AccountVendorsStore from "@/stores/account/AccountVendorsStore";

export function useSearchByPocContent() {
  const { hasExpandedSearch } = useContext(ExpandableSearchContext);
  const { accountVendors, isLoading } = useUnit(AccountVendorsStore);
  const [selectedVendorId, setSelectedVendorId] = useState<string>("");
  const [showContent, setShowContent] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 800);

    return () => clearTimeout(timer);
  }, []);

  useMemo(() => {
    if (accountVendors?.length === 1) {
      setSelectedVendorId(accountVendors[0].vendorId);
    }
    if (accountVendors?.length > 1) {
      setSelectedVendorId("");
    }
  }, [accountVendors]);

  const showLoading = useMemo(() => {
    return hasExpandedSearch && isLoading;
  }, [hasExpandedSearch, isLoading]);

  const showNothing = useMemo(() => {
    return hasExpandedSearch && accountVendors === undefined;
  }, [hasExpandedSearch, accountVendors]);

  const showEmpty = useMemo(() => {
    return hasExpandedSearch && accountVendors?.length === 0;
  }, [hasExpandedSearch, accountVendors]);

  return {
    selectedVendorId,
    setSelectedVendorId,
    showContent,
    showLoading,
    showNothing,
    showEmpty,
  };
}
