import { axiosInstance } from "@bees-grow-shared/services";

import { GROW_AGENT_CALL_SERVICE_ENDPOINTS } from "../endpoints";
import { StartCallResponse } from "../model/StartCallResponse";

export async function getOpenCall(): Promise<StartCallResponse> {
  const { data } = await axiosInstance.get<StartCallResponse>(
    GROW_AGENT_CALL_SERVICE_ENDPOINTS.OPEN_CALL(),
  );

  return data;
}
