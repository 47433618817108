import { constructEndpoint } from "@bees-grow-shared/services";

import { MICROSERVICES } from "@/services/MicroServices";

enum ENDPOINTS {
  ACCOUNTS = "/v2/accounts",
  ACCOUNTS_INFO = "/v2/accounts/{accountId}",
}

export const GROW_ACCOUNT_SEARCH_SERVICE_ENDPOINTS = {
  GET_ACCOUNTS: (accountId: string) =>
    constructEndpoint({
      service: MICROSERVICES.BEES_GROW_BFF,
      endpoint: ENDPOINTS.ACCOUNTS,
      queryParams: { id: accountId },
    }),
  GET_ACCOUNTS_INFO: (accountId: string) =>
    constructEndpoint({
      service: MICROSERVICES.BEES_GROW_BFF,
      endpoint: ENDPOINTS.ACCOUNTS_INFO,
      pathParams: { accountId },
    }),
};
