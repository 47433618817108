import { tokens } from "@bees-grow-shared/theme";
import { Card, Heading, SkeletonLoader } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-4"]};
`;

export const Cards = styled(Card)`
  width: inherit;
  display: flex;
  flex-direction: column;
  padding: 0 ${tokens.measure.space["spacing-4"]};
`;

export const Header = styled.div`
  width: inherit;
  display: flex;
  justify-content: space-between;
`;

export const SubHeader = styled(Heading)`
  width: inherit;
  display: flex;
  justify-content: space-between;
  padding: ${tokens.measure.space["spacing-4"]} 0;
`;

export const SkeletonLoaderSubHeader = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-3"]};
  width: "223px";
`;

export const KpiCard = styled(Card)`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
`;

export const SkeletonTitle = styled.div`
  margin: 1rem 0;
`;

export const SkeletonHeader = styled.div`
  height: ${tokens.measure["line-height"]["lineheight-6"]};
`;

export const SkeletonLoaderHeader = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-5"]};
  width: "108px";
`;
