import { constructEndpoint } from "@bees-grow-shared/services";

import { MICROSERVICES } from "@/services/MicroServices";

enum ENDPOINTS {
  OPEN_CALL = "/agents/open-call",
  NEXT_POC = "/accounts/next",
}

export const GROW_AGENT_CALL_SERVICE_ENDPOINTS = {
  OPEN_CALL: () =>
    constructEndpoint({
      service: MICROSERVICES.BEES_GROW_BFF,
      endpoint: ENDPOINTS.OPEN_CALL,
    }),
  GET_NEXT_POC: () =>
    constructEndpoint({
      service: MICROSERVICES.BEES_GROW_BFF,
      endpoint: ENDPOINTS.NEXT_POC,
    }),
};
