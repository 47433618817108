import { tokens } from "@bees-grow-shared/theme";
import { Heading, Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: ${tokens.measure.space["spacing-2"]};
`;

export const Header = styled(Heading)`
  align-content: end;
`;

export const CurrentValue = styled(Heading)`
  color: ${tokens.color.foundation.txt.default};
`;

export const CurrentValueLabel = styled(Paragraph)`
  color: ${tokens.color.foundation.txt.secondary};
`;

export const ColumnDivider = styled.div`
  border-left: thin solid;
  color: ${tokens.color.component.bg.divider.default};
`;
