import { useIntl } from "react-intl";

import { useStopAutoDialer } from "./hooks/useStopAutoDialer";
import * as Styled from "./StopAutoDialer.styles";

export default function StopAutoDialer() {
  const { formatMessage } = useIntl();
  const { handleOpenStopCallingModal } = useStopAutoDialer();

  return (
    <Styled.StopAutoDialer>
      {formatMessage({ id: "StartCall.AutoDialer.StopCalling.TEXT" })}
      <Styled.StopAutoDialerButton
        data-testid="stop-dialer-button"
        onClick={handleOpenStopCallingModal}
      >
        {formatMessage({ id: "StartCall.AutoDialer.StopCalling.BUTTON" })}
      </Styled.StopAutoDialerButton>
    </Styled.StopAutoDialer>
  );
}
