import {
  AgentKpiDomain,
  AhtDomain,
  CallsDomain,
  HrDomain,
  UpliftDomain,
} from "@/domains/AgentKpiDomain";
import {
  AgentKpiResponse,
  AhtResponse,
  CallsResponse,
  HrResponse,
  UpliftResponse,
} from "@/services/agentKpi/getAgentKpi/model/GetAgentKpiResponse";

export const getAgentKpiConvert = (
  response: AgentKpiResponse,
): AgentKpiDomain => {
  return {
    daily: {
      ...response.daily,
      uplift: convertUplift(response.daily.uplift),
      calls: convertCalls(response.daily.calls),
      hr: convertHr(response.daily.hr),
      aht: convertAht(response.daily.aht),
    },
    monthly: {
      ...response.monthly,
      uplift: convertUplift(response.monthly.uplift),
      calls: convertCalls(response.monthly.calls),
      hr: convertHr(response.monthly.hr),
      aht: convertAht(response.monthly.aht),
    },
  } as AgentKpiDomain;
};

const convertUplift = (
  uplift: UpliftResponse | undefined,
): UpliftDomain | undefined => {
  if (uplift === undefined) {
    return undefined;
  }

  return {
    target: uplift.target ?? 0,
    completed: uplift.completed ?? 0,
    gap: uplift.gap ?? 0,
    total: uplift.total ?? 0,
    percent: uplift.percent ?? 0,
  };
};

const convertCalls = (
  calls: CallsResponse | undefined,
): CallsDomain | undefined => {
  if (calls === undefined) {
    return undefined;
  }

  return {
    ...calls,
    completed: calls.completed ?? 0,
    target: calls.target ?? 0,
    percent: calls.percent ?? 0,
  };
};

const convertHr = (hr: HrResponse | undefined): HrDomain | undefined => {
  if (hr === undefined) {
    return undefined;
  }

  return {
    completed: hr.completed ?? 0,
    target: hr.target ?? 0,
    percent: hr.percent ?? 0,
  };
};

const convertAht = (aht: AhtResponse | undefined): AhtDomain | undefined => {
  if (aht === undefined) {
    return undefined;
  }

  return {
    completed: formatTime(aht.completed),
    target: formatTime(aht.target),
  };
};

export const formatTime = (value: string) => {
  const DEFAULT_TIME = "00:00";
  if (typeof value != "string") return DEFAULT_TIME;
  if (value.length > 5) return value.slice(-5);
  return DEFAULT_TIME.slice(0, -1 * value.length).concat(value);
};
