import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useToast } from "@bees-grow-shared/business-components";
import {
  agentCallSharedService,
  PocCallOrigin,
  useFeatureToggleEnabledByOrgId,
  useSharedUnit,
} from "@bees-grow-shared/services";

import { ACCOUNT_PATH } from "@/config/constants";
import { GROW_PORTAL_CTI } from "@/config/featureToggles";
import { GetNextPocUseCase } from "@/useCases/agentCall/getNextPoc/GetNextPocUseCase";

interface UseGoToNextPocProps {
  handleOnClick: () => void;
  isGoToNextPocLoading: boolean;
  isGoToNextPocDisabled: boolean;
}

export function useGoToNextPoc(): UseGoToNextPocProps {
  const agentCallService = agentCallSharedService();

  const {
    startCall: {
      isLoading,
      error: isGoToNextPocError,
      errorMessage: errorMessageGoToNextPoc,
    },
    pocCallOrigin,
    ctiEnabled,
  } = useSharedUnit(agentCallService);

  const { isEnabled: isCTIEnabled } =
    useFeatureToggleEnabledByOrgId(GROW_PORTAL_CTI);

  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const toast = useToast();

  const missionPriorityErrorMessage = formatMessage({
    id: "StartCall.GetNextPoc.CustomErrors.GROUP_WITHOUT_MISSION_PRIORITY",
  });

  const handleOnClick = useCallback(() => {
    const goToNextPoc = GetNextPocUseCase({ ctiEnabled: isCTIEnabled }).then(
      (response) => {
        navigate(ACCOUNT_PATH);
        return response;
      },
    );

    agentCallService.setGetNextPoc(goToNextPoc);
  }, [agentCallService, isCTIEnabled, navigate]);

  const handleToastType = useCallback(() => {
    if (errorMessageGoToNextPoc === missionPriorityErrorMessage) {
      return "warning";
    }
    return "error";
  }, [errorMessageGoToNextPoc, missionPriorityErrorMessage]);

  const isGoToNextPoc = useMemo(
    () => ctiEnabled && pocCallOrigin === PocCallOrigin.GET_NEXT_CLIENT,
    [ctiEnabled, pocCallOrigin],
  );

  const isGoToNextPocLoading = useMemo(
    () => isLoading && isGoToNextPoc,
    [isLoading, isGoToNextPoc],
  );

  const isGoToNextPocDisabled = useMemo(
    () => isLoading && !isGoToNextPoc,
    [isLoading, isGoToNextPoc],
  );

  useEffect(() => {
    if (isGoToNextPocError && isGoToNextPoc) {
      toast({
        showCloseButton: true,
        type: handleToastType(),
        message: errorMessageGoToNextPoc,
      });
    }
  }, [
    errorMessageGoToNextPoc,
    handleToastType,
    isGoToNextPoc,
    isGoToNextPocError,
    toast,
  ]);

  return {
    handleOnClick,
    isGoToNextPocLoading,
    isGoToNextPocDisabled,
  };
}
