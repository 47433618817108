import { useIntl } from "react-intl";

import { TextButton } from "@hexa-ui/components";

import RotateIcon from "@/assets/agentKpis/RotateIcon";
import { getAgentKpi } from "@/stores/agentKpi/AgentKpiEvents";

import { Content, Text } from "./UpdateButton.styles";

export const UpdateButton = (): JSX.Element => {
  const { formatMessage } = useIntl();

  const handleClick = () => {
    getAgentKpi();
  };

  return (
    <TextButton
      size="medium"
      onClick={handleClick}
      data-testid={`${UpdateButton.name}-${TextButton.name}`}
    >
      <Content>
        <Text>{formatMessage({ id: "AgentKPIs.Header.UPDATE_BUTTON" })}</Text>
        <RotateIcon />
      </Content>
    </TextButton>
  );
};
