import { useEffect } from "react";

import { useStore } from "effector-react";

import { AgentKpiDomain } from "@/domains/AgentKpiDomain";
import { getAgentKpi } from "@/stores/agentKpi/AgentKpiEvents";
import AgentKpiStore from "@/stores/agentKpi/AgentKpiStore";

export interface UseAgentKPIsProps {
  hasAgentKpiError: boolean;
  isAgentKpiLoading: boolean;
  getKPIs: () => Promise<AgentKpiDomain>;
}

export function useAgentKPIs(): UseAgentKPIsProps {
  const { error: hasAgentKpiError, isLoading: isAgentKpiLoading } =
    useStore(AgentKpiStore);

  useEffect(() => {
    getAgentKpi();
  }, []);

  return {
    hasAgentKpiError,
    isAgentKpiLoading,
    getKPIs: getAgentKpi,
  };
}
