import { useIntl } from "react-intl";

import { Button } from "@hexa-ui/components";
import { Phone } from "@hexa-ui/icons";

import { useGoToPocButton } from "./hooks/useGoToPocButton";

interface GoToPocButtonProps {
  selectedVendorId: string;
}
export default function GoToPocButton({
  selectedVendorId,
}: GoToPocButtonProps) {
  const { formatMessage } = useIntl();
  const { submitSelection } = useGoToPocButton(selectedVendorId);

  return (
    <Button
      icon={() => <Phone size="medium" />}
      trailing
      onClick={submitSelection}
      data-testid={GoToPocButton.name}
      disabled={!selectedVendorId}
    >
      {formatMessage({ id: "SearchByPoc.ActionButton.LABEL" })}
    </Button>
  );
}
