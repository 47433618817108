import { tokens } from "@bees-grow-shared/theme";
import { SkeletonLoader } from "@hexa-ui/components";
import styled from "styled-components";

export const SkeletonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

export const KpiHeaderContainer = styled.div`
  display: flex;
  padding: 0 0 1rem 0;
`;

export const SkeletonLoaderTitle = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-6"]};
  width: 11.75rem;
`;

export const SkeletonLoaderButton = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-4"]};
  width: 5.625rem;
`;
