import {
  agentCallSharedService,
  autoDialerSharedService,
  PocCallOrigin,
} from "@bees-grow-shared/services";

import { intl } from "@/i18n";
import { resetAccounts } from "@/stores/account/AccountVendorsEvents";

import { createOpenCallUseCase } from "../../agentCall/createOpenCall/CreateOpenCallUseCase";

interface AutoDialerUseCaseProps {
  accountId: string;
  vendorId: string;
  vendorAccountId: string;
}

export async function AutoDialerUseCase({
  accountId,
  vendorId,
  vendorAccountId,
}: AutoDialerUseCaseProps): Promise<void> {
  const agentCallService = agentCallSharedService();
  const autoDialerService = autoDialerSharedService();

  try {
    const agentCall = await createOpenCallUseCase({
      accountId,
      vendorId,
      vendorAccountId,
      pocCallOrigin: PocCallOrigin.AUTO_DIALER,
      ctiEnabled: true,
    });

    agentCallService.setStartCallState(agentCall);
  } catch (error) {
    resetAccounts();
    autoDialerService.resetAutoDialer();
    agentCallService.resetStartCallState();
    agentCallService.setStartCallState({
      startCall: {
        error: true,
        isLoading: true,
        errorMessage: intl.formatMessage({
          id: "Errors.GENERIC_ERROR",
        }),
      },
    });

    throw error;
  }
}
