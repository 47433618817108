import { Alert, Button } from "@hexa-ui/components";
import { styled } from "styled-components";

import { fadeAnimation } from "@/pages/homePage/HomePage.styles";

import { openCallResumeButtonResponsiveStyles } from "./components/searchByPoc/SearchByPoc.styles";

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 1rem;
`;

interface ButtonsContainerProps {
  expanded: boolean;
}

export const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 1rem;
  z-index: 10;

  ${(props) => fadeAnimation(props.expanded)}
`;

export const Toast = styled(Alert)`
  position: absolute;
  width: 25rem;
  margin-left: 30%;

  > div {
    align-items: center;
    div:nth-child(2) > div {
      width: auto;
    }
  }

  button {
    width: 150px;
  }
`;

export const LoadingButtonContainer = styled.div`
  display: flex;
  ${openCallResumeButtonResponsiveStyles}
`;

export const LoadingButton = styled(Button)`
  width: 12.6875rem;
  cursor: not-allowed;

  grid-template-columns: 1fr 1fr;
  display: grid;
  justify-items: end;

  > div {
    margin-left: 8px;
  }

  > div > svg {
    width: 16px;
    height: 16px;
  }
`;
