import { useContext } from "react";

import { Phone } from "@hexa-ui/icons";

import { ExpandableSearchContext } from "@/contexts/expandableSearch/ExpandableSearchContext";

import StartAutoDialer from "./components/autoDialer/startAutoDialer/StartAutoDialer";
import StopAutoDialer from "./components/autoDialer/stopAutoDialer/StopAutoDialer";
import StopAutoDialerModal from "./components/autoDialer/stopAutoDialerModal/StopAutoDialerModal";
import VendorSelectorModal from "./components/autoDialer/vendorSelectorModal/VendorSelectorModal";
import CallManually from "./components/callManually/CallManually";
import GoToNextPoc from "./components/goToNextPoc/GoToNextPoc";
import OpenCallButton from "./components/openCallButton/OpenCallButton";
import { SearchByPoc } from "./components/searchByPoc/SearchByPoc";
import { useStartCall } from "./hooks/useStartCall";
import * as Styled from "./StartCall.styles";

export default function StartCall() {
  const {
    isOpenCallLoading,
    hasOpenCall,
    hasOpenCallError,
    buttonsContainerRef,
    isAutoDialerEnabled,
    isAutoDialerConnected,
  } = useStartCall();
  const { hasExpandedSearch } = useContext(ExpandableSearchContext);

  if (isOpenCallLoading) {
    return <Loading />;
  }

  if (hasOpenCallError) {
    return <></>;
  }

  if (hasOpenCall) {
    return <OpenCallButton />;
  }

  return (
    <>
      <StopAutoDialerModal />
      <VendorSelectorModal />

      <Styled.ContainerWrapper>
        <Styled.Container data-testid="start-call-container">
          <SearchByPoc />

          <Styled.ButtonsContainer
            ref={buttonsContainerRef}
            expanded={hasExpandedSearch}
          >
            {isAutoDialerEnabled ? (
              <>
                <CallManually />
                <StartAutoDialer />
              </>
            ) : (
              <GoToNextPoc />
            )}
          </Styled.ButtonsContainer>
        </Styled.Container>

        {isAutoDialerConnected && <StopAutoDialer />}
      </Styled.ContainerWrapper>
    </>
  );
}

function Loading() {
  return (
    <Styled.LoadingButtonContainer>
      <Styled.LoadingButton
        data-testid="start-call-loading"
        icon={Phone}
        trailing
        isLoading
      >
        Loading
      </Styled.LoadingButton>
    </Styled.LoadingButtonContainer>
  );
}
