import { useIntl } from "react-intl";

import { Modal, Paragraph } from "@hexa-ui/components";

import VendorSelector from "../../vendorSelector/VendorSelector";
import ModalButtons from "./components/modalButtons/ModalButtons";
import { useVendorSelectorModal } from "./hooks/useVendorSelectorModal";
import * as Styled from "./VendorSelectorModal.styles";

const VendorSelectorModal = () => {
  const { formatMessage } = useIntl();
  const { isModalOpen, selectedVendorId, setSelectedVendorId } =
    useVendorSelectorModal();

  return (
    <Modal.Root
      open={isModalOpen}
      actions={
        <Styled.ModalContainer>
          <VendorSelector
            selected={selectedVendorId}
            onSelect={setSelectedVendorId}
          />
          <ModalButtons selectedVendorId={selectedVendorId} />
        </Styled.ModalContainer>
      }
      title={
        <Styled.TitleHeading data-testid="title-modal" size="H3">
          {formatMessage({ id: "SearchByPoc.Content.TITLE" })}
        </Styled.TitleHeading>
      }
    >
      <Paragraph data-testid="description-modal">
        {formatMessage({ id: "SearchByPoc.Content.DESCRIPTION" })}
      </Paragraph>
    </Modal.Root>
  );
};

export default VendorSelectorModal;
