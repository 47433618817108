import { useCallback, useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useToast } from "@bees-grow-shared/business-components";
import {
  agentCallSharedService,
  PocCallOrigin,
  useFeatureToggleEnabledByOrgId,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { useUnit } from "effector-react";

import { ACCOUNT_PATH } from "@/config/constants";
import { GROW_PORTAL_CTI } from "@/config/featureToggles";
import { ExpandableSearchContext } from "@/contexts/expandableSearch/ExpandableSearchContext";
import { AccountVendorDomain } from "@/domains/AccountVendorsDomain";
import { intl } from "@/i18n";
import { resetAccounts } from "@/stores/account/AccountVendorsEvents";
import AccountVendorsStore from "@/stores/account/AccountVendorsStore";
import { createOpenCallUseCase } from "@/useCases/agentCall/createOpenCall/CreateOpenCallUseCase";

export function useGoToPocButton(selectedVendorId: string) {
  const agentCallService = agentCallSharedService();

  const { accountVendors } = useUnit(AccountVendorsStore);
  const { shrinkSearch } = useContext(ExpandableSearchContext);
  const navigate = useNavigate();
  const {
    startCall: {
      done: startCallDone,
      error: startCallIsError,
      errorMessage: startCallErrorMessage,
    },
    pocCallOrigin,
  } = useSharedUnit(agentCallService);
  const toast = useToast();

  const { isEnabled: isCTIEnabled } =
    useFeatureToggleEnabledByOrgId(GROW_PORTAL_CTI);

  const isSearchByPocStartCall = useMemo(() => {
    return pocCallOrigin === PocCallOrigin.SEARCH_BY_POC;
  }, [pocCallOrigin]);

  useEffect(() => {
    if (isSearchByPocStartCall && startCallIsError) {
      toast({
        showCloseButton: true,
        type: "error",
        message: startCallErrorMessage,
      });
    }
  }, [isSearchByPocStartCall, startCallErrorMessage, startCallIsError, toast]);

  useEffect(() => {
    if (isSearchByPocStartCall && startCallDone && !startCallIsError) {
      shrinkSearch();
      resetAccounts();
      navigate(ACCOUNT_PATH);
    }
  }, [
    isSearchByPocStartCall,
    navigate,
    shrinkSearch,
    startCallDone,
    startCallIsError,
  ]);

  const selectedAccountVendor: AccountVendorDomain = useMemo(() => {
    const accountVendor = accountVendors?.find(
      (accountVendor) => accountVendor.vendorId === selectedVendorId,
    );

    if (selectedVendorId !== "" && accountVendor === undefined) {
      throw new Error(
        intl.formatMessage({
          id: "Errors.GENERIC_ERROR",
        }),
      );
    }

    return accountVendor;
  }, [selectedVendorId, accountVendors]);

  const updateAgentCall = useCallback(
    ({ accountId, vendorId, vendorAccountId }) => {
      const agentCall = createOpenCallUseCase({
        accountId,
        vendorId,
        vendorAccountId,
        pocCallOrigin: PocCallOrigin.SEARCH_BY_POC,
        ctiEnabled: isCTIEnabled,
      });
      agentCallService.setSearchByPoc(agentCall);
    },
    [agentCallService, isCTIEnabled],
  );

  const submitSelection = useCallback(() => {
    const { accountId, vendorId, vendorAccountId } = selectedAccountVendor;

    updateAgentCall({ accountId, vendorId, vendorAccountId });
  }, [selectedAccountVendor, updateAgentCall]);

  return { submitSelection };
}
