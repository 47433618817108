import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { autoDialerSharedService } from "@bees-grow-shared/services";
import { useUnit } from "effector-react";

import { ACCOUNT_PATH } from "@/config/constants";
import { resetAccounts } from "@/stores/account/AccountVendorsEvents";
import AccountVendorsStore from "@/stores/account/AccountVendorsStore";
import { AutoDialerUseCase } from "@/useCases/autoDialer/AutoDialerUseCase/AutoDialerUseCase";

interface UseModalButtonsProps {
  handleGoToPoc: () => void;
  handleCloseModal: () => void;
}

export function useModalButtons(
  selectedVendorId: string,
): UseModalButtonsProps {
  const navigate = useNavigate();
  const autoDialerService = autoDialerSharedService();
  const { accountVendors } = useUnit(AccountVendorsStore);

  const handleCloseModal = useCallback(() => {
    autoDialerService.resetAutoDialer();
    resetAccounts();
  }, [autoDialerService]);

  const findSelectedVendor = useMemo(() => {
    return accountVendors?.find(
      (accountVendor) => accountVendor.vendorId === selectedVendorId,
    );
  }, [accountVendors, selectedVendorId]);

  const handleGoToPoc = useCallback(() => {
    const { accountId, vendorId, vendorAccountId } = findSelectedVendor;

    AutoDialerUseCase({
      accountId,
      vendorId,
      vendorAccountId,
    }).then(() => {
      navigate(ACCOUNT_PATH);
    });
  }, [findSelectedVendor, navigate]);

  return { handleGoToPoc, handleCloseModal };
}
