/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from "react";
import {
  createIntl,
  createIntlCache,
  IntlConfig,
  IntlShape,
  RawIntlProvider,
} from "react-intl";

import { usePreferredLanguageV2 } from "admin-portal-shared-services";

const DEFAULT_LANGUAGE = "en-US";

import MessageMap from "./i18n";
import enUS from "./languages/en-US";
import es419 from "./languages/es-419";
import ptBR from "./languages/pt-BR";

interface IIntlProvider {
  children: ReactNode;
}

export const allMessages: { [language: string]: MessageMap } = {
  "en-US": enUS,
  "pt-BR": ptBR,
  "es-419": es419,
};

export const intl = {} as IntlShape;

export function IntlProvider({
  children,
}: Readonly<IIntlProvider>): JSX.Element {
  const userLanguage =
    usePreferredLanguageV2().preferredLanguage ?? DEFAULT_LANGUAGE;

  const intlOptions = getIntlOptions(userLanguage);

  const cache = createIntlCache();
  const intlShape = createIntl(intlOptions, cache);
  Object.assign(intl, intlShape);

  return <RawIntlProvider value={intlShape}>{children}</RawIntlProvider>;
}

export const getIntlOptions = (userLanguage: string): IntlConfig => {
  const mergedMessages = mergeMessages(
    allMessages as unknown as MessageMap,
    userLanguage,
  );

  return {
    locale: userLanguage,
    messages: mergedMessages,
    defaultLocale: DEFAULT_LANGUAGE,
  };
};

export const flattenObject = (ob: any): any => {
  const toReturn: { [key: string]: any } = {};

  for (const i in ob) {
    if (typeof ob[i] === "object" && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      // eslint-disable-next-line guard-for-in
      for (const x in flatObject) {
        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export const mergeMessages = (
  messagesInput: MessageMap,
  selectedLocale: string,
): Record<string, string> => {
  const defaultMessages = flattenObject(messagesInput[DEFAULT_LANGUAGE]);
  const localeMessages = flattenObject(messagesInput[selectedLocale]);
  return { ...defaultMessages, ...localeMessages };
};

export default IntlProvider;
