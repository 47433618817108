import {
  agentCallSharedService,
  autoDialerSharedService,
} from "@bees-grow-shared/services";

import { REDIRECT_NOT_REQUIRED } from "@/config/constants";
import { intl } from "@/i18n";
import { resetAccounts } from "@/stores/account/AccountVendorsEvents";
import AccountVendorsStore from "@/stores/account/AccountVendorsStore";

import { AutoDialerUseCase } from "../AutoDialerUseCase/AutoDialerUseCase";

export async function ValidateAutoDialerUseCase() {
  const agentCallService = agentCallSharedService();
  const autoDialerService = autoDialerSharedService();

  const { vendorIdAutoDialer } = autoDialerService.getStoreValue();
  const { accountVendors } = AccountVendorsStore.getState();

  try {
    if (!accountVendors || accountVendors.length === 0) {
      throw new Error("No vendors found for account");
    }

    if (!vendorIdAutoDialer && accountVendors.length > 1) {
      autoDialerService.setAutoDialerState({
        showVendorSelectorModal: true,
      });
      return REDIRECT_NOT_REQUIRED;
    }

    const vendorAccount = vendorIdAutoDialer
      ? accountVendors.find((item) => item.vendorId === vendorIdAutoDialer)
      : accountVendors[0];

    if (!vendorAccount) {
      throw new Error("Invalid vendor account");
    }

    const { accountId, vendorId, vendorAccountId } = vendorAccount;
    await AutoDialerUseCase({ accountId, vendorId, vendorAccountId });
  } catch (error) {
    resetAccounts();
    autoDialerService.resetAutoDialer();
    agentCallService.resetStartCallState();
    agentCallService.setStartCallState({
      startCall: {
        error: true,
        isLoading: true,
        errorMessage: intl.formatMessage({
          id: "Errors.GENERIC_ERROR",
        }),
      },
    });

    throw error;
  }
}
