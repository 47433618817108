import { useIntl } from "react-intl";

import NoSearchResultsIcon from "@/assets/searchByPoc/NoSearchResultsIcon";

import VendorSelector from "../Header/components/startCall/components/vendorSelector/VendorSelector";
import GoToPocButton from "./components/goToPocButton/GoToPocButton";
import { useSearchByPocContent } from "./hooks/useSearchByPocContent";
import * as Styled from "./SearchByPocContent.styles";

export default function SearchByPocContent() {
  const {
    selectedVendorId,
    setSelectedVendorId,
    showContent,
    showLoading,
    showNothing,
    showEmpty,
  } = useSearchByPocContent();
  const { formatMessage } = useIntl();

  if (!showContent) {
    return null;
  }

  if (showLoading) {
    return <Loading />;
  }

  if (showNothing) {
    return <></>;
  }

  if (showEmpty) {
    return <EmptyBehavior />;
  }

  return (
    <Styled.Container data-testid={SearchByPocContent.name}>
      <Styled.Content>
        <Styled.Title size="H4">
          {formatMessage({ id: "SearchByPoc.Content.TITLE" })}
        </Styled.Title>
        <Styled.Description>
          {formatMessage({ id: "SearchByPoc.Content.DESCRIPTION" })}
        </Styled.Description>
        <VendorSelector
          selected={selectedVendorId}
          onSelect={setSelectedVendorId}
        />
        <GoToPocButton selectedVendorId={selectedVendorId} />
      </Styled.Content>
    </Styled.Container>
  );
}

function Loading() {
  return (
    <Styled.LoaderContainer
      data-testid={`${SearchByPocContent.name}-${Loading.name}`}
    >
      <Styled.TitleLoader />
      <Styled.SubTitleLoader />
      <Styled.LabelLoader />
      <Styled.ValueLoader />
      <Styled.LabelLoader />
      <Styled.ValueLoader />
      <Styled.LabelLoader />
      <Styled.ValueLoader />
    </Styled.LoaderContainer>
  );
}

function EmptyBehavior() {
  const { formatMessage } = useIntl();

  return (
    <Styled.EmptyBehavior
      data-testid={`${SearchByPocContent.name}-${EmptyBehavior.name}`}
    >
      <NoSearchResultsIcon />
      <Styled.EmptyBehaviorDescription>
        {formatMessage({
          id: "SearchByPoc.EmptySearch.DESCRIPTION",
        })}
      </Styled.EmptyBehaviorDescription>
    </Styled.EmptyBehavior>
  );
}
