import { Dispatch, SetStateAction, useMemo, useState } from "react";

import {
  autoDialerSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";

interface UseVendorSelectorModalProps {
  isModalOpen: boolean;
  selectedVendorId: string;
  setSelectedVendorId: Dispatch<SetStateAction<string>>;
}

export function useVendorSelectorModal(): UseVendorSelectorModalProps {
  const autoDialerService = autoDialerSharedService();
  const [selectedVendorId, setSelectedVendorId] = useState<string>("");
  const { showVendorSelectorModal } = useSharedUnit(autoDialerService);

  const isModalOpen = useMemo(
    () => showVendorSelectorModal,
    [showVendorSelectorModal],
  );

  return {
    isModalOpen,
    selectedVendorId,
    setSelectedVendorId,
  };
}
