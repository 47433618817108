import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { Phone } from "@hexa-ui/icons";

import { ACCOUNT_PATH } from "@/config/constants";

import { ButtonContainer, Container } from "./OpenCallButton.styles";

export default function OpenCallButton() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const title = formatMessage({ id: "StartCall.OpenCall.TITLE" });

  const handleClickButton = () => {
    navigate(ACCOUNT_PATH);
  };

  return (
    <Container>
      <ButtonContainer
        icon={Phone}
        trailing
        onClick={handleClickButton}
        data-testid="open-call-button"
      >
        {title}
      </ButtonContainer>
    </Container>
  );
}
