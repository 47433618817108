import { useIntl } from "react-intl";

import { Phone } from "@hexa-ui/icons";

import { useModalButtons } from "./hooks/useModalButtons";
import * as Styled from "./ModalButtons.styles";

interface ModalButtonsProps {
  selectedVendorId: string;
}

const ModalButtons = ({ selectedVendorId }: ModalButtonsProps) => {
  const { formatMessage } = useIntl();
  const { handleGoToPoc, handleCloseModal } = useModalButtons(selectedVendorId);

  return (
    <Styled.ButtonsContainer>
      <Styled.GoBackButton
        data-testid="cancel-button"
        size="medium"
        variant="secondary"
        onClick={handleCloseModal}
      >
        {formatMessage({
          id: "StartCall.AutoDialer.VendorSelectorModal.CANCEL_BUTTON",
        })}
      </Styled.GoBackButton>

      <Styled.GoToPocButton
        icon={Phone}
        onClick={handleGoToPoc}
        trailing
        data-testid="go-to-poc-button"
        disabled={!selectedVendorId}
      >
        {formatMessage({ id: "SearchByPoc.ActionButton.LABEL" })}
      </Styled.GoToPocButton>
    </Styled.ButtonsContainer>
  );
};

export default ModalButtons;
