import { useIntl } from "react-intl";

import { Divider, Heading } from "@hexa-ui/components";
import { useStore } from "effector-react";

import AgentKpiStore from "@/stores/agentKpi/AgentKpiStore";

import { PercentCompleted } from "../genericKPI/components/percentCompleted/PercentCompleted";
import { TimeCompleted } from "../genericKPI/components/timeCompleted/TimeCompleted";
import { GenericKPI } from "../genericKPI/GenericKPI";
import { MonthlyKPICardSkeleton } from "../kpiCardSkeleton/MonthlyKPICardSkeleton";
import { useToplineUplift } from "../toplineUplift/hooks/useToplineUplift";
import * as Styled from "./MonthlyKPI.styles";

export const MonthlyKPI = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const { monthly, isLoading } = useStore(AgentKpiStore);
  const {
    monthly: { formattedComplete, percent, target },
  } = useToplineUplift();

  if (isLoading) {
    return <Loading />;
  }

  const renderCallListCard = () => (
    <GenericKPI
      title={formatMessage({ id: "AgentKPIs.MonthlyKPIs.CallList.TITLE" })}
      targetValue={monthly?.calls?.target}
      targetCaption={formatMessage({
        id: "AgentKPIs.MonthlyKPIs.CallList.TARGET_TEXT",
      })}
      fit
    >
      <PercentCompleted
        achievementCaption={formatMessage({
          id: "AgentKPIs.MonthlyKPIs.CallList.COMPLETED_TEXT",
        })}
        achievementNumber={monthly?.calls?.completed}
        percentNumber={monthly?.calls?.percent}
      />
    </GenericKPI>
  );

  const renderHitRateCard = () => (
    <GenericKPI
      title={formatMessage({ id: "AgentKPIs.MonthlyKPIs.HitRate.TITLE" })}
      targetValue={monthly?.hr?.target}
      targetCaption={formatMessage({
        id: "AgentKPIs.MonthlyKPIs.HitRate.TARGET_TEXT",
      })}
      fit
    >
      <PercentCompleted
        achievementCaption={formatMessage({
          id: "AgentKPIs.MonthlyKPIs.HitRate.COMPLETED_TEXT",
        })}
        achievementNumber={monthly?.hr?.completed}
        percentNumber={monthly?.hr?.percent}
      />
    </GenericKPI>
  );

  const renderUpliftCard = () => (
    <GenericKPI
      title={formatMessage({ id: "AgentKPIs.MonthlyKPIs.ToplineUplift.TITLE" })}
      targetValue={target.toString().concat("%")}
      targetCaption={formatMessage({
        id: "AgentKPIs.MonthlyKPIs.ToplineUplift.TARGET_TEXT",
      })}
      fit
    >
      <PercentCompleted
        achievementCaption={formatMessage({
          id: "AgentKPIs.MonthlyKPIs.ToplineUplift.UPLIFT_TEXT",
        })}
        achievementNumber={formattedComplete}
        percentNumber={percent}
      />
    </GenericKPI>
  );

  const renderAverageHandleTimeCard = () => (
    <GenericKPI
      title={formatMessage({
        id: "AgentKPIs.MonthlyKPIs.AverageHandleTime.TITLE",
      })}
      targetValue={monthly?.aht?.target}
      targetCaption={formatMessage({
        id: "AgentKPIs.MonthlyKPIs.AverageHandleTime.TARGET_TEXT",
      })}
      fit
    >
      <TimeCompleted
        achievementCaption={formatMessage({
          id: "AgentKPIs.MonthlyKPIs.AverageHandleTime.COMPLETED_TEXT",
        })}
        achievementValue={monthly?.aht?.completed}
      />
    </GenericKPI>
  );

  return (
    <Styled.Container data-testid={`${MonthlyKPI.name}`}>
      <Styled.Header>
        <Heading size="H4" data-testid={`${MonthlyKPI.name}-title`}>
          {formatMessage({ id: "AgentKPIs.MonthlyKPIs.TITLE" })}
        </Heading>
      </Styled.Header>
      <Styled.Cards
        data-testid={`${MonthlyKPI.name}-Cards`}
        elevated="small"
        border="medium"
      >
        <Styled.SubHeader size="H5" data-testid={`${MonthlyKPI.name}-subtitle`}>
          {formatMessage({ id: "AgentKPIs.MonthlyKPIs.SUBTITLE" })}
        </Styled.SubHeader>
        <Divider />
        {monthly?.calls && renderCallListCard()}
        <Divider />
        {monthly?.hr && renderHitRateCard()}
        <Divider />
        {monthly?.uplift && renderUpliftCard()}
        <Divider />
        {monthly?.aht && renderAverageHandleTimeCard()}
      </Styled.Cards>
    </Styled.Container>
  );
};

function Loading() {
  return (
    <Styled.Container data-testid={`${Loading.name}`} key={`${Loading.name}`}>
      <Styled.SkeletonHeader key={`${Loading.name}-header`}>
        <Styled.SkeletonLoaderHeader />
      </Styled.SkeletonHeader>
      <Styled.Cards
        data-testid={`${Loading.name}-Cards`}
        elevated="small"
        border="medium"
        key={`${Loading.name}-cards`}
      >
        <Styled.SubHeader key={`${Loading.name}-subheader`}>
          <Styled.SkeletonLoaderSubHeader />
        </Styled.SubHeader>
        {Array.from({ length: 4 }).map((_, index) => (
          <div key={`${MonthlyKPI.name}-${Loading.name}-${index.toString()}`}>
            <Divider />
            <MonthlyKPICardSkeleton />
          </div>
        ))}
      </Styled.Cards>
    </Styled.Container>
  );
}
