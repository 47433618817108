import { accountSharedService, AgentCall } from "@bees-grow-shared/services";
import { AxiosError } from "axios";

import { intl } from "@/i18n";
import { getNextPoc } from "@/services/agentCall/getNextPoc/GetNextPocService";
import { ERROR_MESSAGES } from "@/services/agentCall/model/StartCallResponse";
import { getAccountsInfoUseCase } from "@/useCases/account/getAccountsInfo/GetAccountsInfoUseCase";

import { getNextPocConvert } from "./converters/GetNextPocConvert";

interface ErrorResponseData {
  code: ERROR_MESSAGES;
}

interface GetNextPocUseCaseProps {
  ctiEnabled: boolean;
}

export async function GetNextPocUseCase({
  ctiEnabled,
}: GetNextPocUseCaseProps): Promise<AgentCall> {
  const accountService = accountSharedService();

  try {
    const getNextPocResponse = await getNextPoc({ ctiEnabled });

    const accountInfoResponse = await getAccountsInfoUseCase({
      accountId: getNextPocResponse.accountId,
      vendorId: getNextPocResponse.vendorId,
    });

    accountService.setAccount(accountInfoResponse);
    return getNextPocConvert(getNextPocResponse);
  } catch (error) {
    const axiosError = error as AxiosError;
    const errorMessage = (axiosError.response?.data as ErrorResponseData)?.code;

    switch (errorMessage) {
      case ERROR_MESSAGES.GROUP_WITHOUT_MISSION_PRIORITY:
        throw new Error(
          intl.formatMessage({
            id: "StartCall.GetNextPoc.CustomErrors.GROUP_WITHOUT_MISSION_PRIORITY",
          }),
        );
      case ERROR_MESSAGES.NO_POCS_IN_CALL_LIST:
        throw new Error(
          intl.formatMessage({
            id: "StartCall.GetNextPoc.CustomErrors.NO_POCS_IN_CALL_LIST",
          }),
        );
      default:
        throw new Error(
          intl.formatMessage({
            id: "Errors.GENERIC_ERROR",
          }),
        );
    }
  }
}
