import { constructEndpoint } from "@bees-grow-shared/services";

import { MICROSERVICES } from "@/services/MicroServices";

enum ENDPOINTS {
  AGENTS_KPIS = "/agents/kpis",
}

export const GROW_AGENT_KPI_SERVICE_ENDPOINTS = {
  GET_AGENTS_KPIS: () =>
    constructEndpoint({
      service: MICROSERVICES.BEES_GROW_BFF,
      endpoint: ENDPOINTS.AGENTS_KPIS,
    }),
};
